import React from 'react';
import salaberren_y_lopez_logo from "../../../assets/img/sponsors/salaberren-y-lopez-logo.png";
import alaya_logo from "../../../assets/img/sponsors/alaya-logo.png";
import ey_logo from "../../../assets/img/sponsors/ey-logo.png";
import microsoft_logo from "../../../assets/img/sponsors/microsoft-logo.png";
import aws_logo from "../../../assets/img/sponsors/aws-logo.png";
import brownrudnick_logo from "../../../assets/img/sponsors/brownrudnick-logo.png";
import oikos from "../../../assets/img/sponsors/oikos-logo.png";
import cde_logo_negro from "../../../assets/img/sponsors/cde_logo_negro.png";
import universidad_de_san_andres_logo from "../../../assets/img/sponsors/universidad-de-san-andres-logo.png";
import logo_embajada_israel from "../../../assets/img/sponsors/logo_embajada_israel.png";


class Sponsors extends React.Component {
    render() {
        return (
            <section className="sponsors">

                <div className="sponsors_max">
                    <div className="title_spon"><p>Sponsors</p></div>

                    <div className="contenedor_spon">
                        <div className="info_sponsor">Platinum</div>
                        <a target="_blank" rel="noopener noreferrer" href="https://alaya-capital.com/en/home-english/"> <img className="logo_alaya" src={alaya_logo} alt="sponsor" /></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.ey.com/es_ar"> <img className="logo_ey" src={ey_logo} alt="sponsor" /></a>



                    </div>

                    <div className="contenedor_spon">
                        <div className="info_sponsor">Gold</div>
                        <a target="_blank" rel="noopener noreferrer" href="https://aws.amazon.com/es/"> <img className="logo_aws" src={aws_logo} alt="sponsor" /></a>
                        <a target="_blank" rel="noopener noreferrer" href="http://www.brownrudnick.com/all-people/"><img className="logo_brownrudnick" src={brownrudnick_logo} alt="sponsor" /></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://startups.microsoft.com/es-es/"> <img className="logo_microsoft" src={microsoft_logo} alt="sponsor" /></a>



                    </div>

                    <div className="contenedor_spon">
                        <div className="info_sponsor">Silver</div>

                        <a target="_blank" rel="noopener noreferrer" href="http://syls.com.ar/"><img className="logo_salaberren" src={salaberren_y_lopez_logo} alt="sponsor" /></a>
                        <a target="_blank" rel="noopener noreferrer" href=" http://oikos.vc/">  <img className="oikos" src={oikos} alt="sponsor" /></a>
                    </div>


                    <div className="contenedor_spon">
                        <div className="info_sponsor">Supporting Organizations</div>

                        <a target="_blank" rel="noopener noreferrer" href="https://www.udesa.edu.ar/emprendedores"><img className="cde_logo_negro" src={cde_logo_negro} alt="sponsor" /></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.udesa.edu.ar/">  <img className="universidad_de_san_andres_logo" src={universidad_de_san_andres_logo} alt="sponsor" /></a>
                        
                        <a target="_blank" rel="noopener noreferrer" href="https://embassies.gov.il/montevideo">  <img className="logo_embajada_israel" src={logo_embajada_israel} alt="sponsor" /></a>
                        
                    </div>
                </div>
            </section>
        )
    }
}

export default Sponsors;