import React from 'react';
import Acordeon from '../acordeon/Acordeon';
import flecha_agenda_miercoles from "../../../assets/img/agenda/flecha-agenda-miercoles.png"


class Dia1 extends React.Component {
    show_modal1 = () => {
        const modal_1 = document.querySelector('#modal_1');
        const body = document.querySelector('body');
        modal_1.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal2 = () => {
        const modal_2 = document.querySelector('#modal_2');
        const body = document.querySelector('body');
        modal_2.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal3 = () => {
        const modal_3 = document.querySelector('#modal_3');
        const body = document.querySelector('body');
        modal_3.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal4 = () => {
        const modal_4 = document.querySelector('#modal_4');
        const body = document.querySelector('body');
        modal_4.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal5 = () => {
        const modal_5 = document.querySelector('#modal_5');
        const body = document.querySelector('body');
        modal_5.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal6 = () => {
        const modal_6 = document.querySelector('#modal_6');
        const body = document.querySelector('body');
        modal_6.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal7 = () => {
        const modal_7 = document.querySelector('#modal_7');
        const body = document.querySelector('body');
        modal_7.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal8 = () => {
        const modal_8 = document.querySelector('#modal_8');
        const body = document.querySelector('body');
        modal_8.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal9 = () => {
        const modal_9 = document.querySelector('#modal_9');
        const body = document.querySelector('body');
        modal_9.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal24 = () => {
        const modal_24 = document.querySelector('#modal_24');
        const body = document.querySelector('body');
        modal_24.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }


    show_modal26 = () => {
        const modal_26 = document.querySelector('#modal_26');
        const body = document.querySelector('body');
        modal_26.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal27 = () => {
        const modal_27 = document.querySelector('#modal_27');
        const body = document.querySelector('body');
        modal_27.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal28 = () => {
        const modal_28 = document.querySelector('#modal_28');
        const body = document.querySelector('body');
        modal_28.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal29 = () => {
        const modal_29 = document.querySelector('#modal_29');
        const body = document.querySelector('body');
        modal_29.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal30 = () => {
        const modal_30 = document.querySelector('#modal_30');
        const body = document.querySelector('body');
        modal_30.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }

    show_modal31 = () => {
        const modal_31 = document.querySelector('#modal_31');
        const body = document.querySelector('body');
        modal_31.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
    }
    show_modal38 = () => {
        const modal_38 = document.querySelector('#modal_38');
        const body = document.querySelector('body');
        modal_38.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }



    show_modal39 = () => {
        const modal_39 = document.querySelector('#modal_39');
        const body = document.querySelector('body');
        modal_39.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal40 = () => {
        const modal_40 = document.querySelector('#modal_40');
        const body = document.querySelector('body');
        modal_40.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal43 = () => {
        const modal_43 = document.querySelector('#modal_43');
        const body = document.querySelector('body');
        modal_43.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal44 = () => {
        const modal_44 = document.querySelector('#modal_44');
        const body = document.querySelector('body');
        modal_44.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal45 = () => {
        const modal_45 = document.querySelector('#modal_45');
        const body = document.querySelector('body');
        modal_45.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    render() {


        return (
            <div className="item_agenda1">
                <p>TUESDAY 24th, NOVEMBER <b>(GMT-3)</b></p>
                {/* La lógica y codigo del acordeon está en la carpeta 'acordeon'  */}
                <Acordeon>
                    <div className="accor">
                        <div className="head">
                            <p> <span>8:45 AM - 9:00 AM </span>  <br />Opening - Cono Sur Investment Forum 2020</p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal1}>Alejandro Sorgentini</b> <p> President at ARCAP </p>
                            <b onClick={this.show_modal2}>Andrés Meirovich</b> <p>President at ACVC  </p>
                            <b onClick={this.show_modal3}>Inés Bonicelli</b> <p>Executive Vice Director at Uruguay XXI</p>



                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>9:00 AM - 9:40 AM <span className="keynote">KEYNOTE SPEAKER</span> </span>  <br />Creation of a Venture Capital Ecosystem with its own identity </p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal24}>Yigal Erlich</b>
                            <p>Founder, Chairman & Managing Partner at The Yozma Group.</p>                   
                            <b onClick={this.show_modal39}>(M) Alejandro Mashad</b>
                            <p >Director at Centro de Entrepreneurship of Universidad de San Andrés</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>9:45 AM - 10:00 AM </span>  <br />Incentives and investment opportunities in Argentina</p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal29}>Sr. Jorge Argüello</b>
                            <p>Argentine Ambassador in the United States</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>10:00 AM - 10:15 AM   </span>  <br />Incentives and investment opportunities in Chile   </p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal4}>Luis Felipe Oliva</b>
                            <p> Financing and Investments Manager in Corfo</p>
                        </div>
                    </div>


                    <div className="accor">
                        <div className="head">
                            <p> <span>10:15 AM - 10:30 AM   </span>  <br />Incentives and investment opportunities in Uruguay </p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal5}>  Martin Benitez</b>
                            <p>IT Investment Specialist at Uruguay XXI</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>10:30 AM - 11:00 AM  <span className="private" >Private Equity</span></span>  <br />Recent investments in the Southern Cone. NotCo and Despegar.com cases</p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal6}>  Ramiro Lauzan </b>
                            <p>Partner at L Catterton</p>
                            <b onClick={this.show_modal30}>Ivonne Cuello</b>
                            <p>CEO in LAVCA</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>11:00 AM - 11:45 AM <span className="private" >Private Equity</span> </span>  <br />The role of the Southern Cone in regional diversification</p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal7}>Jerónimo Bosch</b>
                            <p> Managing Partner at Grupo Pegasus</p>
                            <b onClick={this.show_modal8}>Roberto Chute</b>
                            <p>Partner at The Rohatyn Group</p>
                            <b onClick={this.show_modal26}>Julio Dittborn Chadwick</b>
                            <p>Principal Private Equity at Megeve Investments</p>
                            <b onClick={this.show_modal27}>Colin Curvey</b>
                            <p>IFC Asset Management Company</p>
                            <b onClick={this.show_modal28}>M: Macarena Navarrete</b>
                            <p> Country Managing Partner at EY Chile</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>11:45 AM - 12:30 PM <span className="private" >Private Equity</span> </span>  <br />Limited Partners investing in Cono Sur</p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal31}>Sarah Bennani</b>
                            <p>Investment Officer at IFC Private Equity Funds</p>

                            <b onClick={this.show_modal43}>Andrés Ackermann</b>
                            <p>Principal Investment Officer - Head of Investment Funds at IDB Invest</p>

                            <b onClick={this.show_modal9}>José Sosa del Valle</b>
                            <p>Partner at Lexington Partners</p>

                            <b onClick={this.show_modal44}>M: Ignacio Hecquet</b>
                            <p>Head of Transaction Advisory Services for Argentina, Uruguay and Paraguay at EY</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>3:00 PM - 3:40 PM <span className="private" >Venture Capital</span> </span>  <br />Connectus Medical, an Uruguayan startup product of a coincidence</p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal45}>Javier Artigas Herrera</b>
                            <p>CEO & Founder at Connectus Medical Group</p>
                            <b>Juan Manuel Giner</b>
                            <p>Executive Director at ARCAP</p>


                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>3:45 PM - 4:20 PM <span className="private" >Venture Capital</span> </span>  <br />Diversity as a value in the investing process </p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal40}>Meghan Stevenson</b>
                            <p>Director at Inca Ventures</p>
                            <b onClick={this.show_modal38}>Alfonso Amat</b>
                            <p>Head of Startup Business Development - LatAm at Amazon Web Services (AWS)</p>

                        </div>
                    </div>
                </Acordeon>
            </div>


        );
    }
}

export default Dia1;