import React from 'react';
import './Agenda.css';
import Acordeon from '../acordeon/Acordeon';
import flecha_agenda_miercoles from "../../../assets/img/agenda/flecha-agenda-miercoles.png"


class Dia1 extends React.Component {

    show_modal1 = () => {
        const modal_1 = document.querySelector('#modal_1');
        const body = document.querySelector('body');
        modal_1.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal2 = () => {
        const modal_2 = document.querySelector('#modal_2');
        const body = document.querySelector('body');
        modal_2.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal3 = () => {
        const modal_3 = document.querySelector('#modal_3');
        const body = document.querySelector('body');
        modal_3.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal4 = () => {
        const modal_4 = document.querySelector('#modal_4');
        const body = document.querySelector('body');
        modal_4.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal5 = () => {
        const modal_5 = document.querySelector('#modal_5');
        const body = document.querySelector('body');
        modal_5.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal6 = () => {
        const modal_6 = document.querySelector('#modal_6');
        const body = document.querySelector('body');
        modal_6.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal7 = () => {
        const modal_7 = document.querySelector('#modal_7');
        const body = document.querySelector('body');
        modal_7.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal8 = () => {
        const modal_8 = document.querySelector('#modal_8');
        const body = document.querySelector('body');
        modal_8.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal9 = () => {
        const modal_9 = document.querySelector('#modal_9');
        const body = document.querySelector('body');
        modal_9.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal24 = () => {
        const modal_24 = document.querySelector('#modal_24');
        const body = document.querySelector('body');
        modal_24.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    show_modal26 = () => {
        const modal_26 = document.querySelector('#modal_26');
        const body = document.querySelector('body');
        modal_26.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal27 = () => {
        const modal_27 = document.querySelector('#modal_27');
        const body = document.querySelector('body');
        modal_27.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal28 = () => {
        const modal_28 = document.querySelector('#modal_28');
        const body = document.querySelector('body');
        modal_28.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal29 = () => {
        const modal_29 = document.querySelector('#modal_29');
        const body = document.querySelector('body');
        modal_29.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal30 = () => {
        const modal_30 = document.querySelector('#modal_30');
        const body = document.querySelector('body');
        modal_30.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal31 = () => {
        const modal_31 = document.querySelector('#modal_31');
        const body = document.querySelector('body');
        modal_31.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal38 = () => {
        const modal_38 = document.querySelector('#modal_38');
        const body = document.querySelector('body');
        modal_38.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    

    show_modal39 = () => {
        const modal_39 = document.querySelector('#modal_39');
        const body = document.querySelector('body');
        modal_39.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal40 = () => {
        const modal_40 = document.querySelector('#modal_40');
        const body = document.querySelector('body');
        modal_40.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal43 = () => {
        const modal_43 = document.querySelector('#modal_43');
        const body = document.querySelector('body');
        modal_43.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal44 = () => {
        const modal_44 = document.querySelector('#modal_44');
        const body = document.querySelector('body');
        modal_44.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal45 = () => {
        const modal_45 = document.querySelector('#modal_45');
        const body = document.querySelector('body');
        modal_45.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }




    render() {


        return (
            <div className="item_agenda1">
                <p>MARTES 24 DE NOVIEMBRE <b>(GMT-3)</b></p>
                {/* La lógica y codigo del acordeon está en la carpeta 'acordeon'  */}
                <Acordeon>
                    <div className="accor">
                        <div className="head">
                            <p> <span>8:45 AM - 9:00 AM </span>  <br />Apertura Foro de Inversiones Cono Sur 2020 </p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal1}>Alejandro Sorgentini</b> <p>Presidente en ARCAP</p>
                            <b onClick={this.show_modal2}>Andrés Meirovich</b> <p>Presidente en ACVC</p>
                            <b onClick={this.show_modal3}>Inés Bonicelli</b> <p>ViceDirectora Ejecutiva en Uruguay XXI</p>



                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>9:00 AM - 9:40 AM <span className="keynote">KEYNOTE SPEAKER</span> </span>  <br />Creación de un Ecosistema de Venture Capital con identidad propia  </p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal24}> Yigal Erlich</b>
                            <p>Founder, Chairman & Managing Partner en The Yozma Group.</p>

                            <b onClick={this.show_modal39}>Modera: Alejandro Mashad</b>
                            <p>Director del Centro de Entrepreneurship de la Universidad de San Andrés</p>

                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>9:45 AM - 10:00 AM </span>  <br />Incentivos y Oportunidades de inversión en Argentina </p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal29}>Sr. Jorge Argüello</b>
                            <p>Embajador argentino en los Estados Unidos de América</p>

                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>10:00 AM - 10:15 AM   </span>  <br />Incentivos y Oportunidades de inversión en Chile  </p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal4}>Luis Felipe Oliva</b>
                            <p>Gerente de Financiamiento e Inversión en CORFO</p>
                        </div>
                    </div>


                    <div className="accor">
                        <div className="head">
                            <p> <span>10:15 AM - 10:30 AM   </span>  <br />Incentivos y Oportunidades de inversión en Uruguay  </p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal5}>  Martin Benitez</b>
                            <p>IT Investment Specialist en Uruguay XXI</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>10:30 AM - 11:00 AM  <span className="private" >Private Equity</span></span>  <br />Inversiones recientes en Cono Sur. Casos NotCo y Despegar.com</p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal6}>  Ramiro Lauzan </b>
                            <p>Partner en L Catterton.</p>
                            <b onClick={this.show_modal30}>Ivonne Cuello</b>
                            <p>CEO en LAVCA</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>11:00 AM - 11:45 AM <span className="private" >Private Equity</span> </span>  <br />El papel del Cono Sur en la diversificación regional</p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal7}>Jerónimo Bosch</b>
                            <p> Managing Partner en Grupo Pegasus</p>
                            <b onClick={this.show_modal8}>Roberto Chute</b>
                            <p>Partner en The Rohatyn Group</p>
                            <b onClick={this.show_modal26}>Julio Dittborn Chadwick</b>
                            <p> Principal Private Equity en Megeve Investments</p>
                            <b onClick={this.show_modal27}>Colin Curvey</b>
                            <p>Fund Head, IFC Asset Management Company</p>
                            <b onClick={this.show_modal28}>Modera: Macarena Navarrete</b>
                            <p>Country Managing Partner en EY Chile</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>11:45 AM - 12:30 PM <span className="private" >Private Equity</span> </span>  <br />Limited Partners con presencia en Cono Sur</p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal31}>Sarah Bennani</b>
                            <p>Investment Officer en IFC Private Equity Funds</p>

                            <b onClick={this.show_modal43}>Andrés Ackermann</b>
                            <p>Principal Investment Officer - Head of Investment Funds en IDB Invest</p>

                            <b onClick={this.show_modal9}>José Sosa del Valle</b>
                            <p>Partner en Lexington Partners</p>
                            <b onClick={this.show_modal44}>Modera: Ignacio Hecquet</b>
                            <p>Socio en EY | Transaction Advisory Services para Argentina, Uruguay y Paraguay </p>

                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>3:00 PM - 3:40 PM <span className="private" >Venture Capital</span> </span>  <br />Connectus Medical, un emprendimiento uruguayo producto de una casualidad</p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal45}>Javier Artigas Herrera</b>
                            <p>CEO y Founder en Connectus Medical Group</p>
                            <b>Juan Manuel Giner</b>
                            <p>Director Ejecutivo en ARCAP</p>


                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>3:45 PM - 4:20 PM <span className="private" >Venture Capital</span> </span>  <br />La diversidad como un valor a la hora de emprender e invertir </p>
                            <img src={flecha_agenda_miercoles} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal40}>Meghan Stevenson</b>
                            <p>Directora en Inca Ventures</p>
                            <b onClick={this.show_modal38}>Alfonso Amat</b>
                            <p>Responsable del Desarrollo del Ecosistema Emprendedor para América Latina en Amazon Web Services. (AWS)</p>

                        </div>
                    </div>
                </Acordeon>
            </div>


        );
    }
}

export default Dia1;