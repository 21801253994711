import React from 'react';
import './Organizadores.css';
import no_image from "../../../assets/img/not_image.png";
import acvc_logo from "../../../assets/img/organizadores/acvc-logo.png";
import arcap_logo from "../../../assets/img/organizadores/arcap-logo.png";
import uruguay_xxi_logo from "../../../assets/img/organizadores/uruguay-xxi-logo.png";


class Organizadores extends React.Component {
    render() {
        return (
            <section className="organizadores">

                <div className="max_org">
                    <div className="title_org"><p>Organizadores</p></div>

                    <div className="contenedor_org">
                        <a target="_blank" rel="noopener noreferrer" href="https://acvc.cl/">   <img src={acvc_logo} alt="organizador" /></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://arcap.org/">     <img src={arcap_logo} alt="organizador" /></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.investinuruguay.uy/es/">   <img src={uruguay_xxi_logo} alt="organizador" /></a>
                    </div>
                </div>
            </section>
        )
    }
}

export default Organizadores;