import React from 'react';
import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Ingles from './components/Ingles';
import Spanish from './components/Spanish';

function App() {

    return (
      <div className="App">
        <Router>
          {/* Cada componente se encuentra en la carpeta de 'componentes'*/}
          <Switch>
            <Route exact path="/" component={Spanish}  />
            <Route path="/en" component={Ingles}  />
          </Switch>

        </Router>
      </div>
    );
  
}

export default App;


