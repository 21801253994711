import React from 'react';
import './Agenda.css';
import Acordeon from '../acordeon/Acordeon';
import flecha_agenda_jueves from "../../../assets/img/agenda/flecha-agenda-jueves.png"



class Dia2 extends React.Component {

    show_modal10 = () => {
        const modal_10 = document.querySelector('#modal_10');
        const body = document.querySelector('body');
        modal_10.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal11 = () => {
        const modal_11 = document.querySelector('#modal_11');
        const body = document.querySelector('body');
        modal_11.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal12 = () => {
        const modal_12 = document.querySelector('#modal_12');
        const body = document.querySelector('body');
        modal_12.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal13 = () => {
        const modal_13 = document.querySelector('#modal_13');
        const body = document.querySelector('body');
        modal_13.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal14 = () => {
        const modal_14 = document.querySelector('#modal_14');
        const body = document.querySelector('body');
        modal_14.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal15 = () => {
        const modal_15 = document.querySelector('#modal_15');
        const body = document.querySelector('body');
        modal_15.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal16 = () => {
        const modal_16 = document.querySelector('#modal_16');
        const body = document.querySelector('body');
        modal_16.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal17 = () => {
        const modal_17 = document.querySelector('#modal_17');
        const body = document.querySelector('body');
        modal_17.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal18 = () => {
        const modal_18 = document.querySelector('#modal_18');
        const body = document.querySelector('body');
        modal_18.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal19 = () => {
        const modal_19 = document.querySelector('#modal_19');
        const body = document.querySelector('body');
        modal_19.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal20 = () => {
        const modal_20 = document.querySelector('#modal_20');
        const body = document.querySelector('body');
        modal_20.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal21 = () => {
        const modal_21 = document.querySelector('#modal_21');
        const body = document.querySelector('body');
        modal_21.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal22 = () => {
        const modal_22 = document.querySelector('#modal_22');
        const body = document.querySelector('body');
        modal_22.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');


    }

    show_modal23 = () => {
        const modal_23 = document.querySelector('#modal_23');
        const body = document.querySelector('body');
        modal_23.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    show_modal24 = () => {
        const modal_24 = document.querySelector('#modal_24');
        const body = document.querySelector('body');
        modal_24.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal25 = () => {
        const modal_25 = document.querySelector('#modal_25');
        const body = document.querySelector('body');
        modal_25.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    show_modal32 = () => {
        const modal_32 = document.querySelector('#modal_32');
        const body = document.querySelector('body');
        modal_32.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal33 = () => {
        const modal_33 = document.querySelector('#modal_33');
        const body = document.querySelector('body');
        modal_33.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal34 = () => {
        const modal_34 = document.querySelector('#modal_34');
        const body = document.querySelector('body');
        modal_34.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal35 = () => {
        const modal_35 = document.querySelector('#modal_35');
        const body = document.querySelector('body');
        modal_35.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal36 = () => {
        const modal_36 = document.querySelector('#modal_36');
        const body = document.querySelector('body');
        modal_36.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal37 = () => {
        const modal_37 = document.querySelector('#modal_37');
        const body = document.querySelector('body');
        modal_37.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal38 = () => {
        const modal_38 = document.querySelector('#modal_38');
        const body = document.querySelector('body');
        modal_38.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }



    show_modal40 = () => {
        const modal_40 = document.querySelector('#modal_40');
        const body = document.querySelector('body');
        modal_40.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal41 = () => {
        const modal_41 = document.querySelector('#modal_41');
        const body = document.querySelector('body');
        modal_41.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal42 = () => {
        const modal_42 = document.querySelector('#modal_42');
        const body = document.querySelector('body');
        modal_42.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal44 = () => {
        const modal_44 = document.querySelector('#modal_44');
        const body = document.querySelector('body');
        modal_44.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal45 = () => {
        const modal_45 = document.querySelector('#modal_45');
        const body = document.querySelector('body');
        modal_45.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal46 = () => {
        const modal_46 = document.querySelector('#modal_46');
        const body = document.querySelector('body');
        modal_46.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal47 = () => {
        const modal_47 = document.querySelector('#modal_47');
        const body = document.querySelector('body');
        modal_47.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal48 = () => {
        const modal_48 = document.querySelector('#modal_48');
        const body = document.querySelector('body');
        modal_48.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal49 = () => {
        const modal_49 = document.querySelector('#modal_49');
        const body = document.querySelector('body');
        modal_49.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal50 = () => {
        const modal_50 = document.querySelector('#modal_50');
        const body = document.querySelector('body');
        modal_50.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal51 = () => {
        const modal_51 = document.querySelector('#modal_51');
        const body = document.querySelector('body');
        modal_51.classList.toggle('show_modal');
        body.classList.toggle('no_scroll')
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal52 = () => {
        const modal_52 = document.querySelector('#modal_52');
        const body = document.querySelector('body');
        modal_52.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }




    render() {


        return (

            <div className="item_agenda3">
                <p >MIÉRCOLES 25 DE NOVIEMBRE <b>(GMT-3)</b></p>
                {/* La lógica y codigo del acordeon está en la carpeta 'acordeon'  */}
                <Acordeon>

                    <div className="accor">
                        <div className="head">
                            <p> <span>8:45 AM - 9:00 AM <span className="private" >Venture Capital </span> </span>  <br />Apertura - El Capital Emprendedor como motor para el desarrollo del Cono Sur </p>
                            <img src={flecha_agenda_jueves} alt="Flecha" />
                        </div>
                        <div className="body">

                            <b onClick={this.show_modal10}>Diego Gonzalez Bravo</b> <br />
                            <p>Vicepresidente Ejecutivo en ARCAP</p>
                            <b onClick={this.show_modal42}>Francisco Guzmán</b>
                            <p>Vice Presidente en ACVC</p>
                            <b onClick={this.show_modal11}>Isabella Antonaccio</b>
                            <p>IT Aftercare Specialist, en Uruguay XXI</p>

                        </div>
                    </div>
                    <div className="accor">
                        <div className="head">
                            <p> <span>9:00 AM - 9:40 AM <span className="keynote">KEYNOTE SPEAKER</span></span>  <br />La innovación como motor para la transformación de una nación
                            Saul Singer, Autor “Start-up Nation: La historia del milagro económico de Israel”</p>
                            <img src={flecha_agenda_jueves} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal37}>Saul Singer</b> <br />
                            <p>CoWriter “Start-up Nation: The Story of Israel's Economic Miracle"</p>
                            <b onClick={this.show_modal47}>Introducción: Yoed Magen</b>
                            <p>Embajador Israelí en Uruguay</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>9:40 AM - 10:15 AM <span className="private" >Venture Capital</span></span>  <br />Inversores internacionales con presencia en Cono Sur</p>
                            <img src={flecha_agenda_jueves} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal19}>Stephen Lile</b>
                            <p>Cofounder & Managing Partner en Oxbridge Capital Partners</p>
                            <b onClick={this.show_modal33}>Eric Manlunas </b>
                            <p>Founder y Managing Partner en Wavemaker Partners</p>
                            <b onClick={this.show_modal34}>Modera: María de los Ángeles Romo</b>
                            <p>Gerenta Startup Chile</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>10:15 AM - 10:45 AM <span className="private" >Corporate Venture Capital</span></span>  <br />Corporate Venture Capital internacional vinculado a LATAM. <br />Mouro Capital, nuevo vehículo de Venture Capital para la Industria Fintech</p>
                            <img src={flecha_agenda_jueves} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal17}>Manuel Silva</b> <p>General Partner en Mouro Capital</p>
                            <b onClick={this.show_modal18}>Agustín Rotondo</b> <p>Regional Manager en Wayra Hispanoamérica</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span>10:45 AM - 11:15 AM <span className="private" >Venture Capital</span></span>  <br />Gestores de Fondos de Cono Sur con alcance regional</p>
                            <img src={flecha_agenda_jueves} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal13}>Antonia Rojas</b> <p>Partner en ALLVP</p>
                            <b onClick={this.show_modal14}>Luis Bermejo</b> <p>Managing Partner en Alaya Capital Partners</p>
                            <b onClick={this.show_modal15}>Valentina Terranova</b> <p>Partner en Draper Cygnus</p>
                            <b>Modera: Isabelle Chaquiriand</b> <p>Directora General en Xcala</p>
                        </div>
                    </div>

                    <div className="accor ">
                        <div className="head">
                            <p> <span  >12:00 PM - 12:30 PM <span className="private" >Venture Capital</span></span>  <br />FireSideChat con Ricardo Donoso de Olx Autos. Nuevo unicornio de socios chilenos.</p>
                            <img src={flecha_agenda_jueves} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal24}>Ricardo Donoso</b>
                            <p>Co founder y CEO Americas Frontier Car Group, nuevo unicornio de socios chilenos.</p>
                            <b onClick={this.show_modal52}>Quin Garcia</b>
                            <p>Managing Director en Autotech Ventures, Mobility & Transportation Venture Capital Investor</p>
                        </div>
                    </div>

                    <div className="accor">
                        <div className="head">
                            <p> <span  >12:45 PM - 1:15 PM <span className="private" >Venture Capital</span></span>  <br />FireSideChat con Sergio Fogel, Co-Founder del primer unicornio de Uruguay y Hernán Kazah, Co-Founder & Managing Partner en Kaszek Ventures</p>
                            <img src={flecha_agenda_jueves} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal20}>Sergio Fogel</b>
                            <p>Co-Founder en DLocal</p>
                            <b onClick={this.show_modal21}>Hernán Kazah</b>
                            <p>Co-Founder & Managing Parter (Kaszek Ventures)</p>
                        </div>
                    </div>

                    <div className="accor ">
                        <div className="head">
                            <p> <span  >1:30 PM - 2:00 PM <span className="private" >Venture Capital</span></span>  <br />FireSideChat con Eugenio Pace, CEO y CoFundador de Auth0, último y quinto unicornio argentino.</p>
                            <img src={flecha_agenda_jueves} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal23}>Eugenio Pace</b>
                            <p>CEO y CoFundador de Auth0</p>
                            <b onClick={this.show_modal32}>Eduardo Mangarelli</b>
                            <p>Presidente en Endeavor Uruguay / Socio en IC Ventures</p>
                        </div>
                    </div>

                    <div className="accor ">
                        <div className="head">
                            <p> <span  >3: 00 PM - 3:35 PM <span className="private" >Corporate Venture Capital</span></span>  <br />Cambiando el enfoque de simplemente producir MÁS a producir MEJOR.</p>
                            <img src={flecha_agenda_jueves} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal49}>Paimun Amini</b>
                            <p>Director de Venture Investments en Leaps de Bayer</p>
                            <b onClick={this.show_modal50}>Matias Peire</b>
                            <p>Fundador y Managing Partner en GRIDX</p>
                        </div>
                    </div>

                    <div className="accor ">
                        <div className="head">
                            <p> <span  >3: 45 PM - 4:15 PM <span className="private" >Venture Capital</span></span>  <br />Corporate Venture Capital: evolución regional y mundial </p>
                            <img src={flecha_agenda_jueves} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal35}>Patricia Pastor</b>
                            <p>Managing Director en GoHub by Global Omnium & General Partner en GoHub Ventures</p>
                            <b onClick={this.show_modal25}>Mariano Amartino</b>
                            <p>Managing Director Microsoft for startups LATAM</p>
                        </div>
                    </div>

                    <div className="accor ">
                        <div className="head">
                            <p> <span >4: 15 PM - 4:45 PM <span className="private" >Venture Capital</span></span>  <br />Inversiones de Impacto: el Venture Capital ante un cambio de paradigma </p>
                            <img src={flecha_agenda_jueves} alt="Flecha" />
                        </div>
                        <div className="body">
                            <b onClick={this.show_modal51}>Rodrigo Villar</b>
                            <p>Socio Fundador en New Ventures y Adobe Capital</p>
                            <b onClick={this.show_modal36}>Carmen Correa</b>
                            <p>Directora de Operaciones de Pro Mujer & Directora Pymecapital</p>
                            <b onClick={this.show_modal22}>Maria Laura Tinelli</b>
                            <p>Directora en Acrux Partner</p>
                        </div>
                    </div>
                </Acordeon>
            </div>
        );
    }
}

export default Dia2;