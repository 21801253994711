import React from 'react';
import './Acerca.css'
import logo_fics from "../../../assets/img/logo-fics.png";


class Acerca extends React.Component {
    render() {
        return (
            <section className="acerca">
                <div className="acerca_max">
                    <hr />
                    <div className="acerca_contenedor">
                        <div className="acerca_info">
                            <img src={logo_fics} alt="fics" />
                            Acerca del Foro <br /> de Inversiones <br /> <b>Cono Sur</b>
                        </div>

                        <div className="texto_acerca">
                            <h3>El mayor Foro sobre la industria de Capital privado, Emprendedor y Semilla en el Cono Sur.</h3> <br />
                            <p>Organizado por la <b>Asociación Chilena de Venture Capital (ACVC), la Asociación Argentina de Capital Privado, Emprendedor y Semilla (ARCAP), y la agencia de Promoción de Inversiones, Exportaciones e Imagen País Uruguay XXI</b>, FICS 2020 reunirá a los actores más relevantes de esta industria para discutir tendencias y oportunidades de inversión y ofrecer una mirada global sobre las oportunidades de desarrollo del sector.

                            </p>

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Acerca;