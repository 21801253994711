import React from 'react';

// IMPORTADOS DE LA CARPETA SPANISH
import Menu from '../components/spanish/menu/Menu'
import Agenda from '../components/spanish/agenda/Agenda'
import Banner from '../components/spanish/banner/Banner'
import Acerca from '../components/spanish/acerca/Acerca'
import Contacto from '../components/spanish/contacto/Contacto'
import Organizadores from '../components/spanish/organizadores/Organizadores'
import Sponsors from '../components/spanish/sponsors/Sponsors'
import Speakers from '../components/spanish/speakers/Speakers'


// Esta es la sección de español. Cada sección está en su carpeta 
// correspondiente de spanish/
// Todo el css está en cada carpeta de spanish correspondiente a cada sección
// En la sección de inglés NO hay css individuales, utiliza los mismos del spanish
// Esto con el objetivo de no duplicar css sino solo para contenidos.
class Spanish extends React.Component {


    render() {
        return (
            <section className="spanish">
                <section className="fondo_puntos"></section>

                <div>
                    < Menu />
                    <Banner />
                    <Acerca />
                    <Agenda />
                    <Speakers />
                    <Organizadores />
                    <Sponsors />
                    <Contacto />
                </div>
            </section>
        )
    }
}

export default Spanish;