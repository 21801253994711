import React from 'react';
import './Menu.css';
import { NavLink, Redirect } from 'react-router-dom'
import { Link } from 'react-scroll'
import barra_tricolor from "../../../assets/img/header/barra-tricolor.png";
import logo_fics from "../../../assets/img/logo-fics.png";
import hamburguesa from "../../../assets/img/header/boton-hamburguesa.png";



class Menu extends React.Component {
    state = {
        reload: false
    }

    show_menu = () => {
        const menu_idioma = document.querySelector('.menu_idioma')
        const flecha_idioma = document.querySelector('.flecha_idioma')
        menu_idioma.classList.toggle('show_idioma')
        flecha_idioma.classList.toggle('turn_flecha')
    }

    show_menu_movil = () => {
        const menu_movil = document.querySelector('#menu_movil')
        menu_movil.classList.toggle('menu_show')
        const body = document.querySelector('body');
        body.classList.toggle('no_scroll');
    }

    show_registro = () => {
        const modal_registro = document.querySelector('.modal_registro');
        // const body = document.querySelector('body');
        modal_registro.classList.toggle('show_modal_registro');
        // body.classList.toggle('no_scroll');
    }


    refreshPage = () => {
        this.setState(
            { reload: true },
            () => this.setState({ reload: false })
        )

    }

    render() {


        return (
            <header>
                <div className="menu_contenedor desktop_menu">
                    <img className="barra_tricolor" src={barra_tricolor} alt="barra_tricolor" />

                    <div className="logos">
                        <img src={logo_fics} alt="fics" />
                        <div className="idioma_choose"><span>Español</span>  <span className="flecha_idioma" onClick={this.show_menu}>^</span></div>

                        <div className="menu_idioma">
                            <a href="/#/en" target="_blank" rel="noopener noreferrer" className="item_idioma">English</a>
                            {/* <NavLink to="/" className="item_idioma">Español</NavLink> */}
                        </div>
                    </div>

                    {/* HAMBURGUESA PARA EL MENU MOVIL */}
                    <div className="hamburguesa movil" onClick={this.show_menu_movil} ><img src={hamburguesa} alt="hamburguesa" /></div>

                    <nav className="menu desktop">

                        <ul>
                            <Link to="banner" smooth={true}> <li className="home">Home</li></Link>
                            <Link to="acerca" smooth={true} offset={-110}> <li>Acerca del foro</li></Link>
                            <Link to="agenda" smooth={true} > <li>Agenda</li></Link>
                            <Link to="speakers" smooth={true} offset={-100} > <li>Speakers</li></Link>
                            <Link to="organizadores" smooth={true} > <li>Organizadores</li></Link>
                            <Link to="sponsors" smooth={true} > <li>Sponsors</li></Link>
                            <Link to="contacto" smooth={true} > <li>Contacto</li></Link>
                            {/* <Link to="banner" smooth={true} onClick={this.show_registro}> <li className="registro">Registro</li></Link> */}
                           

                        </ul>
                    </nav>
                </div>

                <nav onClick={this.show_menu_movil} className="menu movil" id={"menu_movil"}>
                    <div className="menu_idioma">
                        <a href="/#/en" target="_blank" rel="noopener noreferrer" className="item_idioma">English</a>

                        {/* <NavLink to="/" className="item_idioma">Español</NavLink> */}
                    </div>
                    <ul>
                        <Link onClick={this.show_menu_movil} to="banner" smooth={true}> <li className="home">Home</li></Link>
                        <Link onClick={this.show_menu_movil} to="acerca" smooth={true} > <li>Acerca del foro</li></Link>
                        <Link onClick={this.show_menu_movil} to="agenda" smooth={true} > <li>Agenda</li></Link>
                        <Link onClick={this.show_menu_movil} to="speakers" smooth={true} offset={-100} > <li>Speakers</li></Link>
                        <Link onClick={this.show_menu_movil} to="organizadores" smooth={true} > <li>Organizadores</li></Link>
                        <Link onClick={this.show_menu_movil} to="sponsors" smooth={true} > <li>Sponsors</li></Link>
                        <Link onClick={this.show_menu_movil} to="contacto" smooth={true} > <li>Contacto</li></Link>
                        {/* <Link to="banner" smooth={true} onClick={this.show_registro} > <li className="registro">Registro</li></Link> */}


                    </ul>
                </nav>

            </header>
        )
    }
}

export default Menu;