import React from 'react';
import './Speakers.css';
import cierre from "../../../assets/img/agenda/boton_cierre.png";



class Modals extends React.Component {
    show_modal1 = () => {
        const modal_1 = document.querySelector('#modal_1');
        const body = document.querySelector('body');
        modal_1.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal2 = () => {
        const modal_2 = document.querySelector('#modal_2');
        const body = document.querySelector('body');
        modal_2.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal3 = () => {
        const modal_3 = document.querySelector('#modal_3');
        const body = document.querySelector('body');
        modal_3.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal4 = () => {
        const modal_4 = document.querySelector('#modal_4');
        const body = document.querySelector('body');
        modal_4.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal5 = () => {
        const modal_5 = document.querySelector('#modal_5');
        const body = document.querySelector('body');
        modal_5.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal6 = () => {
        const modal_6 = document.querySelector('#modal_6');
        const body = document.querySelector('body');
        modal_6.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal7 = () => {
        const modal_7 = document.querySelector('#modal_7');
        const body = document.querySelector('body');
        modal_7.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal8 = () => {
        const modal_8 = document.querySelector('#modal_8');
        const body = document.querySelector('body');
        modal_8.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal9 = () => {
        const modal_9 = document.querySelector('#modal_9');
        const body = document.querySelector('body');
        modal_9.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal10 = () => {
        const modal_10 = document.querySelector('#modal_10');
        const body = document.querySelector('body');
        modal_10.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal11 = () => {
        const modal_11 = document.querySelector('#modal_11');
        const body = document.querySelector('body');
        modal_11.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal12 = () => {
        const modal_12 = document.querySelector('#modal_12');
        const body = document.querySelector('body');
        modal_12.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal13 = () => {
        const modal_13 = document.querySelector('#modal_13');
        const body = document.querySelector('body');
        modal_13.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal14 = () => {
        const modal_14 = document.querySelector('#modal_14');
        const body = document.querySelector('body');
        modal_14.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal15 = () => {
        const modal_15 = document.querySelector('#modal_15');
        const body = document.querySelector('body');
        modal_15.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal16 = () => {
        const modal_16 = document.querySelector('#modal_16');
        const body = document.querySelector('body');
        modal_16.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal17 = () => {
        const modal_17 = document.querySelector('#modal_17');
        const body = document.querySelector('body');
        modal_17.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal18 = () => {
        const modal_18 = document.querySelector('#modal_18');
        const body = document.querySelector('body');
        modal_18.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal19 = () => {
        const modal_19 = document.querySelector('#modal_19');
        const body = document.querySelector('body');
        modal_19.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal20 = () => {
        const modal_20 = document.querySelector('#modal_20');
        const body = document.querySelector('body');
        modal_20.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal21 = () => {
        const modal_21 = document.querySelector('#modal_21');
        const body = document.querySelector('body');
        modal_21.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal22 = () => {
        const modal_22 = document.querySelector('#modal_22');
        const body = document.querySelector('body');
        modal_22.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal23 = () => {
        const modal_23 = document.querySelector('#modal_23');
        const body = document.querySelector('body');
        modal_23.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    show_modal24 = () => {
        const modal_24 = document.querySelector('#modal_24');
        const body = document.querySelector('body');
        modal_24.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    show_modal25 = () => {
        const modal_25 = document.querySelector('#modal_25');
        const body = document.querySelector('body');
        modal_25.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal26 = () => {
        const modal_26 = document.querySelector('#modal_26');
        const body = document.querySelector('body');
        modal_26.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal27 = () => {
        const modal_27 = document.querySelector('#modal_27');
        const body = document.querySelector('body');
        modal_27.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal28 = () => {
        const modal_28 = document.querySelector('#modal_28');
        const body = document.querySelector('body');
        modal_28.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal29 = () => {
        const modal_29 = document.querySelector('#modal_29');
        const body = document.querySelector('body');
        modal_29.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal30 = () => {
        const modal_30 = document.querySelector('#modal_30');
        const body = document.querySelector('body');
        modal_30.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal31 = () => {
        const modal_31 = document.querySelector('#modal_31');
        const body = document.querySelector('body');
        modal_31.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    show_modal32 = () => {
        const modal_32 = document.querySelector('#modal_32');
        const body = document.querySelector('body');
        modal_32.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal33 = () => {
        const modal_33 = document.querySelector('#modal_33');
        const body = document.querySelector('body');
        modal_33.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal34 = () => {
        const modal_34 = document.querySelector('#modal_34');
        const body = document.querySelector('body');
        modal_34.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal35 = () => {
        const modal_35 = document.querySelector('#modal_35');
        const body = document.querySelector('body');
        modal_35.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal36 = () => {
        const modal_36 = document.querySelector('#modal_36');
        const body = document.querySelector('body');
        modal_36.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }
    show_modal37 = () => {
        const modal_37 = document.querySelector('#modal_37');
        const body = document.querySelector('body');
        modal_37.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal38 = () => {
        const modal_38 = document.querySelector('#modal_38');
        const body = document.querySelector('body');
        modal_38.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal39 = () => {
        const modal_39 = document.querySelector('#modal_39');
        const body = document.querySelector('body');
        modal_39.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal40 = () => {
        const modal_40 = document.querySelector('#modal_40');
        const body = document.querySelector('body');
        modal_40.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal41 = () => {
        const modal_41 = document.querySelector('#modal_41');
        const body = document.querySelector('body');
        modal_41.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal42 = () => {
        const modal_42 = document.querySelector('#modal_42');
        const body = document.querySelector('body');
        modal_42.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal43 = () => {
        const modal_43 = document.querySelector('#modal_43');
        const body = document.querySelector('body');
        modal_43.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal44 = () => {
        const modal_44 = document.querySelector('#modal_44');
        const body = document.querySelector('body');
        modal_44.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal45 = () => {
        const modal_45 = document.querySelector('#modal_45');
        const body = document.querySelector('body');
        modal_45.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal46 = () => {
        const modal_46 = document.querySelector('#modal_46');
        const body = document.querySelector('body');
        modal_46.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal47 = () => {
        const modal_47 = document.querySelector('#modal_47');
        const body = document.querySelector('body');
        modal_47.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal48 = () => {
        const modal_48 = document.querySelector('#modal_48');
        const body = document.querySelector('body');
        modal_48.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal49 = () => {
        const modal_49 = document.querySelector('#modal_49');
        const body = document.querySelector('body');
        modal_49.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal50 = () => {
        const modal_50 = document.querySelector('#modal_50');
        const body = document.querySelector('body');
        modal_50.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal51 = () => {
        const modal_51 = document.querySelector('#modal_51');
        const body = document.querySelector('body');
        modal_51.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal52 = () => {
        const modal_52 = document.querySelector('#modal_52');
        const body = document.querySelector('body');
        modal_52.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    render() {
        return (
            <section className="modal">
                {/***************************** MODAL 1 *******************************************/}
                <div className="modal_content" id={'modal_1'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    {/* <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.google.com/"></a> */}
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal1} src={cierre} alt="cierre" /></div>

                                <b>Alejandro Sorgentini</b>
                                <p>Presidente ARCAP</p>
                                Socio Fundador de Victoria Capital Partners, firma regional dedicada al Private Equity y sucesora de DLJ South American Partners. Se incorporó a DLJMB como asociado en el año 2000 en su filial de Buenos Aires, Argentina, donde se dedicó a las actividades de Private Equity de la compañía en America del Sur. Previo a su incorporación a DLJMB, el Sr. Sorgentini ocupó diversos cargos dentro de Motorola Inc. en Argentina, donde se concentró en el gerenciamiento de las operaciones de telefonía celular de la empresa en Argentina, Chile, Paraguay, Uruguay y Bolivia. El Sr. Sorgentini obtuvo el título de Ingeniero Industrial de la Universidad Católica Argentina y recibió un M.B.A. de Harvard Business School. Formó parte de los directorios de Cameo Chile S.A., Vitopel S.A., Peñaflor S.A., Trapiche S.A., Torneos y Competencias S.A., International Health Services Argentina S.A., Prensiplast S.A., PAB S.A., PABSA S.A. y Grupo Santillana de Ediciones S.L. Actualmente es Director en Energy Fitness Clubs SpA., Grupo Los Grobo S.A. y Satus Ager S.A.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 2 *******************************************/}
                <div className="modal_content" id={'modal_2'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/andr%C3%A9s-meirovich-bellolio-20b2b29/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal2} src={cierre} alt="cierre" /></div>

                                <b>Andrés Meirovich</b>
                                <p>Presidente ACVC</p>
                                Presidente de la Asociación Chilena de Venture Capital (ACVC),
                                y Managing Partner de Genesis Ventures. Doctor of Business
                                Administration (c) SDA Bocconi, Italia. MBA del MIT, EEUU. Ingeniero
                                Comercial y Magíster en Finanzas de la Universidad del Desarrollo.
                                Participó en el Executive Program in Entrepreneurial Innovation
                                de Stanford University. Columnista del Diario Financiero desde el
                                año 2013. En el año 2010 recibió el premio al alumni destacado por
                                la Universidad del Desarrollo, y el premio a jóvenes líderes en 2012
                                otorgado por Diario Financiero. Director de Amipass, Predictable
                                Media y Wenu Work. Consejero de Sofofa. Andrés ha visto más de 400
                                proyectos, y tiene más de 10 años de experiencia en la industria del
                                Venture Capital. Desde el 2018 es Profesor del MBA de la Universidad
                                Adolfo Ibáñez.

                            </div>
                        </div>
                    </div>
                </div>
                {/***************************** MODAL 3 *******************************************/}
                <div className="modal_content" id={'modal_3'}>
                    <div className="modal">
                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ines-bonicelli-4872119/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal3} src={cierre} alt="cierre" /></div>

                                <b>Inés Bonicelli </b>
                                <p>ViceDirectora Ejecutiva en Uruguay XXI</p>
                                "Inés Bonicelli es vicedirectora ejecutiva de Uruguay XXI desde abril de 2020.
                                Tiene un MBA con foco en Finanzas por la Michigan Business School, es licenciada en Dirección de Empresas por la Universidad Católica del Uruguay y diplomada en
                                Economía por el el Instituto de Economía de Montevideo. En su actividad laboral destaca por ser fundadora y directora de Atlantika Group, una consultora de servicios tipo one-stop-shop para inversores inmobiliarios extranjeros que buscan instalarse en Uruguay o invertir en el país. En este rol desarrolló estrategias de presentación de Uruguay como destino de inversores y de obtención de residencia fiscal y ha sido responsable de la captación y atención de clientes extranjeros.
                                Fue además socia de Bado y Asociados Sotheby’s International Realty, así como fundadora y directora ejecutiva de ReachingU - a Foundation for Uruguay, la organización sin fines de lucro basada en Estados Unidos para financiar y apoyar proyectos educativos en Uruguay, que ha canalizado más de USD 15 millones.
                                Anteriormente fue gerente de Operaciones en Imperio JP (Nueva York, Estados Unidos) y gerente de Operaciones del Banco Bozano Simonsen (Rio de Janeiro, Brasil).
                                Es voluntaria en ReachingU, Centro Quebracho y Techo Uruguay"
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 4 *******************************************/}
                <div className="modal_content" id={'modal_4'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/luisfelipeolivadiaz/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal4} src={cierre} alt="cierre" /></div>

                                <b>Luis Felipe Oliva</b>
                                <p>Gerente de Financiamiento e Inversión en CORFO</p>
                                Ingeniero Civil Industrial de la Universidad de Chile, MBA de ESADE y Máster en Economía de New York University (EE.UU). Actualmente, se desempeña como gerente de Inversiones y Financiamiento en la Corporación de Fomento de la Producción (Corfo) en Chile. Cuenta con más de 20 años de experiencia en la industria financiera. Fue gerente general de MBI Administradora General de Fondos S.A, de Zurich Investments, miembro del Grupo Zurich Financial Services y subgerente de Estrategias de Inversión en Chilena Consolidada de Seguros de Vida. Previamente trabajó en el área de inversiones de AFP Cuprum y en el área de Wealth Management en Citibank, Chile. Adicionalmente, es profesor part-time en la Facultad de Economía y Negocios de la Universidad de Chile.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 5 *******************************************/}
                <div className="modal_content" id={'modal_5'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/martinbenitezaramendia/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal5} src={cierre} alt="cierre" /></div>

                                <b>Martin Benitez</b>
                                <p>IT Investment Specialist en Uruguay XXI</p>

                                Martín Benítez, es Licenciado en Administración de Empresas de la UCUDAL, y cuenta con una especialización en eCommerce de la Universidad de León. También realizó estudios de Marketing y Management en la New York University y en Stanford. Con experiencia en varios sitios de comercio electrónico, ha trabajado para Saxo Bank como CMO para America Latina y empresas relacionadas con Blockchain y Criptomonedas. En 2017 lanzó el portal educativo Bitcoinsycriptos.com y en 2018 inauguró la primer Exchange de Criptomonedas en Uruguay. Desde el 2018 es docente del Curso de Criptomonedas en la Facultad de Ciencias Empresariales de la UCUDAL. Actualmente es Especialista en empresas Tecnológicas del equipo de Inversiones de Uruguay XXI.


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 6 *******************************************/}
                <div className="modal_content" id={'modal_6'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ramiro-lauzan-2a5181/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal6} src={cierre} alt="cierre" /></div>

                                <b>Ramiro Lauzan</b>
                                <p>Partner en L Catterton.</p>
                                "El Sr. Lauzan es socio de L Catterton, enfocado en América Latina. Antes de unirse a L Catterton, fue socio gerente de Humus Capital Partners, una firma de independiente de Private Equity middle-market que cofundó en América del Sur, con inversiones en bienes de consumo, biotecnología y energías renovables. Anteriormente, el Sr. Lauzan pasó más de 8 años en McKinsey & Company, donde prestó servicios a clientes de múltiples sectores en América Latina, Asia y Australia. Antes de eso, también trabajó como investment banker en Lehman Brothers y en Venture Capital en el grupo L.I.D.. El señor Lauzan es miembro del Consejo de Administración de Biosidus Group y Embotelladora Metropolitana.

                                El Sr. Lauzan posee una licenciatura en Economía de Negocios de la Universidad Torcuato di Tella en Argentina y un MBA del Columbia Business School (Yosaji Morita scholar, Beta Gamma Sigma Honor Society Dean’s list) en Nueva York, E.E.U.U..
                                "

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 7 ******************************************/}
                <div className="modal_content" id={'modal_7'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/jeronimo-bosch-42a224/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal7} src={cierre} alt="cierre" /></div>

                                <b>Jerónimo Bosch</b>
                                <p>Managing Partner en Grupo Pegasus</p>
                                "Jerónimo Bosch es Presidente y Socio Director del Grupo Pegasus, una firma basada en Argentina y Colombia dedicada a la creación y desarrollo de negocios a largo plazo en America Latina.
                                Pegasus ha invertido en real estate comercial, comercio minorista, servicios financieros, tecnología, agronegocios, residencias para adultors mayores, y operaciones de hostals, en empresas y proyectos con presencia en America Latina y los E.E.U.U. El Sr. Bosch es Director Ejecutivo de ARG Realty y Director de Farmacity y Freddo.
                                El Sr. Bosch tiene más de 24 años de experiencia en los mercados de inversiones inmobiliarias de capital privado y emprendedor en America Latina y E.E.U.U. Antes de unirse a Pegasus en 2003, vivió ocho años en Nueva York (E.E.U.U), São Paulo, (Brasil) y San Francisco (E.E.U.U) trabajando para Morgan Stanley y Salomon Brothers en diferentes roles.
                                Posee una licenciatura en economía de la Universidad de San Andrés en Buenos Aires, Argentina y es fluente en español, inglés y portugués."
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 8 ******************************************/}
                <div className="modal_content" id={'modal_8'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/roberto-chute-4b3310/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal8} src={cierre} alt="cierre" /></div>

                                <b>Roberto Chute</b>
                                <p>Partner en The Rohatyn Group</p>
                                "El Sr. Chute es socio de The Rohatyn Group (TRG), una empresa que gestiona activos exclusivamente en mercados emergentes, y que fue establecida en los Estados Unidos en 2003.
                                El Sr. Chute está basado en Buenos Aires y lidera las inversiones en mercados privados de TRG en América Latina en una amplia gama de sectores que incluyen farmacéutico, tecnología de la información, logística, agronegocios, bienes raíces, productos de consumo masivo, venta minorista y servicios mineros, entre otros.
                                El Sr. Chute tiene más de 18 años de experiencia en inversiones de private equity en Latinoamérica y es miembro del Directorio de varias compañías del portfolio de TRG en la región. Antes de unirse a TRG en 2006, el Sr. Chute fue Principal de Banc of America Equity Partners Latin America (BAEP). Durante su permanencia en BAEP, el Sr. Chute fue responsable de inversiones en México, Brasil y Argentina, y se desempeñó como miembro del Directorio en varias empresas de diversos sectores. El Sr. Chute también trabajó en la banca de inversión de Goldman Sachs y anteriormente se desempeñó como consultor en Strat Consulting, una firma consultora de alta gerencia en Argentina.
                                El Sr. Chute comenzó su carrera en el Grupo Techint en Buenos Aires. El Sr. Chute recibió su MBA de la Kellogg Graduate School of Management de la Northwestern University y una licenciatura en Ingeniería Industrial del Instituto Tecnológico de Buenos Aires, Argentina.
"
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 9 ******************************************/}
                <div className="modal_content" id={'modal_9'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/jose-sosa-del-valle-b13b38/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal9} src={cierre} alt="cierre" /></div>

                                <b>José Sosa del Valle</b>
                                <p>Partner en Lexington Partners</p>
                                "El señor Sosa del Valle es Socio en Lexington Partners donde lidera los activos latinoamericanos de la firma y está a cargo de la oficina de Santiago. El señor Sosa del Valle está enfocado en la originación y evaluación de inversiones secundarias de private equity no estadounidense, en la originación y evaluación de oportunidades de co inversión directa en América Latina, y de levantamiento de capital en la región para los fondos de Lexington. Antes de unirse a Lexington en 2008, el señor Sosa del Valle fue asociado en la banca de inversión en Goldman Sachs. El señor Sosa del Valle es graduado del Instituto Tecnológico de Buenos Aires con el título de ingeniero industrial y posee un MBA de la Columbia Business School."
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 10 ******************************************/}
                <div className="modal_content" id={'modal_10'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/diego-gonzalez-bravo-b143821/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal10} src={cierre} alt="cierre" /></div>

                                <b>Diego Gonzalez Bravo</b>
                                <p>Vicepresidente Ejecutivo en ARCAP</p>
                                "Diego González Bravo es Vicepresidente de ARCAP-Asociación Argentina de Capital Privado, Emprendedor y Semilla, Senior Managing Partner en Draper Cygnus y Managing Partner en Cygnus Capital. Es además co-fundador del Club de Ángeles de Cygnus.
                                Cuenta con una amplia experiencia en Capital Emprendedor y Finanzas Corporativas. Durante los últimos años ha trabajado en inversiones de Capital Emprendedor, Estrategias Comerciales y Fusiones y Adquisiciones. Fue Socio en S&A-Morrison KSI y estaba a cargo del departamento de Finanzas Corporativas. Es miembro fundador de la Asociación de Emprendedores de Argentina-ASEA. Antes de Cygnus y S&A, Diego trabajó en PricewaterhouseCoopers en finanzas y el departamento de auditoría donde se especializó en fusiones y adquisiciones, valuaciones, due diligence, captación de fondos y reestructuración financiera."
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 11 ******************************************/}
                <div className="modal_content" id={'modal_11'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/isaantonaccio/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal11} src={cierre} alt="cierre" /></div>

                                <b>Isabella Antonaccio</b>
                                <p>IT Aftercare Specialist, en Uruguay XXI</p>

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 12 ******************************************/}
                <div className="modal_content" id={'modal_12'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/yigal-erlich-879869/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal12} src={cierre} alt="cierre" /></div>

                                <b>Yigal Erlich</b>
                                <p>Founder, Chairman & Managing Partner en The Yozma Group.</p>
                                "Yigal Erlich es el fundador y managing partner de Yozma, que se fundó en 1992, como un fondo de capital de riesgo de USD 100 millones, patrocinado por el gobierno de Israel. Yozma fundó diez nuevos fondos de venture capital que fueron los pilares de la vibrante industria del capital emprendedor en Israel.
                                Luego de su privatización en 1997, Yozma estableció dos fondos más, administró más de USD 220 millones y ha invertido en más de 45 empresas.
                                Entre 1984 y 1992, el Sr. Erlich se desempeñó como Científico Jefe del Ministerio de Industria y Comercio de Israel, con un presupuesto anual de USD 200 millones, principalmente dirigido a proyectos de I + D de empresas de alta tecnología. Además, el Sr. Erlich inició el Programa de Incubadoras de Tecnología que condujo a la creación de 24 Centros de Incubación en todo Israel e inició el establecimiento de varios acuerdos binacionales de cooperación en I + D industrial y tecnológica con Canadá, Francia, los Países Bajos, Singapur y España, entre otros.
                                El Sr. Erlich fue el Vicepresidente del Consejo Nacional de Investigación y Desarrollo de Israel y cofundador y primer presidente de la Asociación de Venture Capital de Israel.
                                El Sr. Erlich fue director de las principales empresas gubernamentales (Dead Sea Works, Israel Chemicals, Israel Oil Refineries), Hadassah's commercialization company - Hadassit, y Technion Research and Development Co. Ltd, así como en muchos directorios de empresas del portafolio que Yozma ha invertido. Fue miembro del directorio, por cinco años, de la Compañía de Venture Capital de Rusia (RVC), un fondo de fondos del gobierno de mil millones de dólares y fue consultor de varios gobiernos
                                A día de hoy, Erlich and Yozma es una empresa de consultoría e inversiones de venture capital, en Israel y en el extranjero.
                                El Sr. Erlich tiene un B.Sc. de la Universidad Bar Ilan y M.Sc. en Química y un MBA de la Universidad Hebrea de Jerusalén.
"

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 13 ******************************************/}
                <div className="modal_content" id={'modal_13'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/antoniarojase/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal13} src={cierre} alt="cierre" /></div>

                                <b>Antonia Rojas Eing</b>
                                <p>Partner en ALLVP</p>
                                Antonia es Partner de ALLVP, fondo líder en inversión de Venture Capital en latinoamérica de habla hispana. Antes de ALLVP Antonia fue partner del fondo chileno de Venture Capital Manutara Ventures y fue gerente de inversiones en el fondo inmobiliario Trigono Inversiones. Es ingeniera Comercial de la Pontificia Universidad Católica de Chile y realizó un Master en Emprendimiento Social en Hult International Business School en San Francisco. Antonia es miembro de la Clase 25 de Kauffman Fellows, y ha sido reconocida dentro de las top inversionistas en tecnología en la región el año 2018 y 2019 por LAVCA.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 14 ******************************************/}
                <div className="modal_content" id={'modal_14'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/luisbermejo/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal14} src={cierre} alt="cierre" /></div>

                                <b>Luis Bermejo</b>
                                <p>Managing Partner en Alaya Capital Partners</p>
                                Co-fundador y Managing Partner de ALAYA Capital Partners, fondo de Venture Capital con presencia en Argentina, Chile y USA, enfocado en proyectos tecnológicos innovadores de Latam.
                                Miembro del Board de Endeavor Córdoba y docente y mentor en diversas instituciones educativas y ONGs del ecosistema emprendedor.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 15 ******************************************/}
                <div className="modal_content" id={'modal_15'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/valentina-terranova-85496294/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal15} src={cierre} alt="cierre" /></div>

                                <b>Valentina Terranova</b>
                                <p>Partner en Draper Cygnus</p>
                                "Valentina Terranova fue emprendedora y posee gran experiencia en desarrollo de negocios, marketing y finanzas. Está profundamente involucrada en el mundo del capital emprendedor y fundó la Aceleradora Embarca en 2017 en Argentina. Participa activamente en la Asociación Argentina de Vanture Capital y en Endeavor Argentina como miembro del directorio de Endeavor Cuyo.
                                Se incorporó a Draper Cygnus como socia para trabajar en la generación de dealflow, en el desarrollo del portfolio y en las relaciones con los inversores.
                                Valentina tiene una licenciatura en Administración de Empresas de la Universidad Nacional de Cuyo, una especialización en Marketing Digital de ADEN Business School y realizó un posgrado en Venture Investments de la Universidad Austral.

"

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 16 ******************************************/}
                <div className="modal_content" id={'modal_16'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/isabellechaquiriand/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal16} src={cierre} alt="cierre" /></div>

                                <b>Isabelle Chaquiriand</b>
                                <p>Presidente de Xcala</p>
                                "Isabelle Chaquiriand es presidente de Xcala, Asociación Latinoamericana de Inversión Ángel y consultora del BID en temas de desarrollo empresarial. Adicionalmente es vicepresidente de ATMA, empresa líder en diseño, fabricación y distribución de envases para la industria y el hogar en Uruguay.
                                Es doctor in Business Administration de Grenoble Ecole de Management, Francia; Máster en Dirección y Administración de Empresas por el IEEM, Universidad de Montevideo; Contadora Pública por la Universidad de la República Oriental del Uruguay y ha realizado estudios en emprendimiento en Babson College, Estados Unidos.
                                Es senadora por Uruguay en la WBAF (World Business Angel Forum). Es integrante de la Comisión Directiva de la Cámara de Industrias del Uruguya e integra el Comité Asesor de Fundación Astur, que atiende sectores vulnerables de la población. Fue fundadora de Corazoncitos, en apoyo a niños con cardiopatías congénitas en Uruguay.
                                Es columnista en varios medios de comunicación y conferencista invitada a nivel internacional en temas de desarrollo empresarial.
                                Fue galardonada en 2010 y 2011 como empresario del año de la industria manufacturera y en 2016 recibió el reconocimiento a la emprendedora emergente del año por Ernst & Young. Actualmente ocupa el puesto nro 16 entre los 100 mejores líderes empresariales del Uruguay de acuerdo con el Ranking Merco."
                            </div>
                        </div>
                    </div>
                </div>
                {/***************************** MODAL 17 ******************************************/}
                <div className="modal_content" id={'modal_17'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/msilvamartinez/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal17} src={cierre} alt="cierre" /></div>

                                <b>Manuel Silva</b>
                                <p>General Partner en Mouro Capital</p>
                                "Manuel supervisa la estrategia, las relaciones con los inversores, los recursos y los procesos de Mouro Capital como nuestro socio autorizado por la FCA. Su actividad de inversión se centra principalmente en el Reino Unido, Europa y América Latina. Manuel es parte del directorio de a55, bonify y Crosslend y, como observador, de Autofi, Creditas, Curve, Elliptic, Klar, Roostify y Tradeshift, entre otros. Manuel ha estado invirtiendo en Fintech desde 2010, después de haber pasado cinco años en San Francisco con BBVA Ventures (ahora Propel Venture Partners) antes de mudarse a Londres en 2015. Es un entusiasta del senderismo, viajero mundial (+66 países) y aficionado al vino.
                                Educación: Licenciatura y Maestría en Administración de Empresas y Auditoría (CUNEF), MPhil PPE, MPhil DevEcon, PhD ""all but thesis"" Econ (Sciences Po Paris, Chinese University of Hong Kong)."
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 18 ******************************************/}
                <div className="modal_content" id={'modal_18'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/agrotondo/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal18} src={cierre} alt="cierre" /></div>

                                <b>Agustín Rotondo</b>
                                <p>Regional Manager en Wayra Hispanoamérica</p>
                                Agustin Rotondo es Ingeniero Industrial egresado del ITBA con un MBA de doble titulación de ESIC Business School en Madrid y SISU University en Shanghai. En 2008 se integró al grupo Telefónica, donde se desempeñó en diferentes roles locales y globales. Luego de ejercer un año y medio como Country Manager de Wayra Argentina, hoy lidera el cargo de Regional Manager para Wayra Hispam.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 19 ******************************************/}
                <div className="modal_content" id={'modal_19'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/stephen-lile-b44aa1120/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal19} src={cierre} alt="cierre" /></div>

                                <b>Stephen Lile</b>
                                <p>CoFundador y Managing Partner en Oxbridge Capital Partners</p>
                                Stephen Lile es académico de la Universidad de Oxford y Cambridge, también es un ejecutivo de medios, ganador del premio Emmy y emprendedor consumado. Es un reconocido líder de opinión y orador público en capital de riesgo y emprendimiento con más de 25 años de éxito en medios, tecnología, IoT, banca de inversión y gestión de patrimonio. Ha lanzado varias empresas disruptivas con escala global, lo que ha dado como resultado salidas exitosas para fundadores e inversores. Stephen también es productor de Hollywood con más de 100 horas de programación de la cadena Prime-Time como productor ejecutivo de PBS y de películas independientes. Entre sus reconocimientos mediáticos se incluyen el ganador del Festival de Cine AFI Premier Mundial 2006 y el Premio del Gobernador de Oregón por ser un pionero e innovador en tecnología de medios.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 20 ******************************************/}
                <div className="modal_content" id={'modal_20'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/sergiofogel/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal20} src={cierre} alt="cierre" /></div>

                                <b>Sergio Fogel</b>
                                <p>Co-Founder en DLocal</p>

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 21 ******************************************/}
                <div className="modal_content" id={'modal_21'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/hernan-kazah-95810/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal21} src={cierre} alt="cierre" /></div>

                                <b>Hernán Kazah</b>
                                <p>Co-Founder & Managing Parter en Kaszek Ventures</p>
                                "Hernán Kazah es cofundador y managing partner de Kaszek Ventures, la mayor firma de Venture Capital de la industria de tecnología en Latinoamérica.
                                Antes de fundar Kaszek Ventures en 2011, co-fundó MercadoLibre (Nasdaq: MELI), la mayor plataforma de comercio electrónico y pagos online en Latinoamérica, donde trabajo por 12 años – los primeros 9 años como COO y los últimos 3 años como CFO.
                                Previo a esto, trabajó como Brand Manager en Procter & Gamble Co. y fue analista financiero en el Programa de Desarrollo de las Naciones Unidas y en una firma de corredores de bolsa.
                                El Sr. Kazah es actualmente miembro del directorio de varias compañías de tecnología en Latinoamérica.
                                El Sr. Kazah obtuvo su MBA en Stanford University y se graduó magna cum laude en Economía de la Universidad de Buenos Aires.
                                Fue seleccionado Emprendedor Endeavor en 1999, galardonado con la distinción “The Outstanding Young Persons of Argentina” por la Junior Chamber International en 2004, elegido como Emprendedor Endeavor Establecido en 2009, y obtuvo el premio Entrepreneur Master EY 2018 en Argentina, junto con su socio, Nicolás Szekasy."
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 22 ******************************************/}
                <div className="modal_content" id={'modal_22'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/marialauratinelli/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal22} src={cierre} alt="cierre" /></div>

                                <b>Maria Laura Tinelli</b>
                                <p>Directora en Acrux Partner</p>
                                "María Laura es Directora de Acrux Partners, una firma especializada en inversión de impacto con sede en Argentina y el Reino Unido. Cuenta con una vasta experiencia en la estructuración y asesoría de vehículos y oportunidades de inversión sostenible y de impacto. Destacándose entre otros el desarrollo junto a BID FOMIN de los lineamientos para el primer fondo de inversión de impacto para el Cono Sur, la estructuración y lanzamiento del primer Bono de Impacto Social de Argentina, y la asesoría para el primer Bono Sostenible alineado a Estándares ICMA y ODS del Banco de Desarrollo Productivo de Bolivia.
                                Es Miembro del Directorio del Global Steering Group on Impact Investment, Miembro del Comité Asesor de la Red Latinoamericana de Venture Philanthropy,  y representante de SDG Impact Standards para el Programa de Naciones Unidas para el Desarrollo para América Latina.  Lidera el grupo de Trabajo en Inversión de Impacto para Argentina y es co-fundadora de la Red Latinoamericana de Pago por Resultados y del LatinSIF. "

                            </div>
                        </div>
                    </div>
                </div>


                {/***************************** MODAL 23 ******************************************/}
                <div className="modal_content" id={'modal_23'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/eugeniop/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal23} src={cierre} alt="cierre" /></div>

                                <b>Eugenio Pace</b>
                                <p>CoFundador y CEO en Auth</p>
                                Eugenio Pace fundó Auth0 a principios de 2013 junto con el CTO y su “hermano de armas”, Matias Woloski, mientras vivían a 7.000 millas de distancia. Desde entonces, Pace ha jugado un papel instrumental en llevar a Auth0 a ser una empresa líder de identity management, preferida por los developers y en la cual confían empresas globales. Con más de 9.000 clientes en 70 países, y asegurando 4.5 mil millones de transacciones de login por mes, Auth0 es una marca global confiable para facilitar la identidad y proteger los datos de los clientes y sus usuarios.

                                Pace pasó toda su carrera profesional facilitando el trabajo de los developers, creando herramientas estándar de la industria y contenido, y servicios para computación en la nube, movilidad e identidad. Antes de Auth0, estuvo más de una década en Microsoft, donde lideró equipos de Product Management y de Ingeniería. También co-escribió varios libros sobre cloud computing y identity management.

                                Eugenio es Ingeniero de formación. Cursó sus estudios en el Instituto Tecnológico de Buenos Aires (ITBA).
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 24 ******************************************/}
                <div className="modal_content" id={'modal_24'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ricardodonosoinsunza/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal24} src={cierre} alt="cierre" /></div>

                                <b>Ricardo Donoso</b>
                                <p>Cofundador y CEO Americas Frontier Car Group / Olx Autos</p>
                                Ricardo creció en Suecia y se mudó de regreso a su país natal, Chile, cuando tenía 16 años. Era tenista junior de alto rango y se retiró temprano debido a lesiones cuando tenía 17 años. A los 27 años inició su carrera empresarial al fundar su primera empresa Celmedia en 2002. Celmedia fue la primera empresa de servicios móviles de valor agregado en América Latina, la cual se expandió a 11 países y exportó en 2010. En 2011, cofundó la marca de telefonía móvil de mercados emergentes Azumi, de la que salió en 2015 y se convirtió en una de las marcas de teléfonos móviles más vendidas en varios mercados al vender 5 millones de teléfonos en más de 20 países. En 2016, cofundó Frontier Car Group, un mercado automotriz de autos usados ​​que simplifica la experiencia de compra y venta de autos usados ​​mediante el uso de tecnología y desarrollo de infraestructura. Frontier Car Group fue valorado recientemente como Unicornio después de que Naspers hiciera una inversión de 482 millones de dólares a través de su subsidiaria Olx Group y luego fusionara FCG con la unidad de mercados emergentes de Olx. Ricardo forma parte de varios consejos de administración de empresas de base tecnológica y ha llevado a cabo con éxito múltiples transformaciones. También es socio fundador de Manutara Ventures, una firma de capital de riesgo con sede en Latam.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 25 ******************************************/}
                <div className="modal_content" id={'modal_25'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/marianoamartino/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal25} src={cierre} alt="cierre" /></div>

                                <b>Mariano Amartino</b>
                                <p>Managing Director at Microsoft for Startups LATAM</p>
                                "Mariano Amartino es Director de Microsoft for startups para Latinoamérica, donde dirige los programas de startups y vinculación con el ecosistema de innovación.

                                Previamente, fue Director General de Wayra, la Aceleradora de startups de Telefónica que opera en 12 países de la región, donde Wayra tiene un portafolio de más de 600 empresas innovadoras en 20 industrias digitales diferentes.

                                De 2009 a 2011, cuando se incorporó a Wayra, Mariano era el CEO de Hipertextual, llevándola a ser la principal red de blogs en español. Actualmente se desempeña como miembro de la junta y asesor de varias startups y todavía escribe en uberbin.net, uno de los primeros blogs en español donde cubre temas de comunidades en línea, Internet y nuevos medios."

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 26 ******************************************/}
                <div className="modal_content" id={'modal_26'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/julio-dittborn-b4b7932b/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal26} src={cierre} alt="cierre" /></div>

                                <b>Julio Dittborn Chadwick</b>
                                <p>Principal Private Equity en Megeve Investments</p>
                                "Julio Dittborn es Principal en Private Equity en Megeve Investments, el Family Office de la familia Solari Donaggio. Antes de unirse al equipo de Private Equity, de 2006 a 2010, trabajó en la mesa de trading, primero como analista y luego como administrador de la cartera de acciones de América Latina. Tiene 14 años de experiencia invirtiendo en múltiples sectores y países de América Latina, incluyendo entre otros Perú, Colombia y Paraguay.
                                Ha liderado varias empresas bajo el control de Megeve en diferentes industrias como generación de energía, inmobiliaria, forestal, entre otras. Su participación ha sido principalmente en asuntos estratégicos y financieros. También se ha desempeñado como miembro del directorio de Colgener, una planta de energía de 330MW en Colombia y Parque del Recuerdo, una empresa de cementerios en Chile.
                                El Sr. Dittborn tiene un MBA de The Wharton School en la Universidad de Pennsylvania (WG12). Es Ingeniero Comercial de la Universidad Católica de Chile."

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 27******************************************/}
                <div className="modal_content" id={'modal_27'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/colincurvey/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal27} src={cierre} alt="cierre" /></div>

                                <b>Colin Curvey</b>
                                <p>Fund Head at IFC Asset Management Company</p>
                                Colin tiene veinte años de experiencia en capital privado internacional que abarca adquisiciones de control en Europa Occidental hasta inversiones de capital de crecimiento en mercados emergentes. Ha liderado inversiones en una variedad de sectores, incluidos servicios financieros, consumo, energía e infraestructura, y ha servido como director de varias empresas de cartera en Europa, América Latina y África
                                Actualmente Colin dirige un fondo de US $1,000 millones centrado en América Latina y África en IFC Asset Management Company (“AMC”). AMC es el GP cautivo de la Corporación Financiera Internacional, que maneja capital institucional de inversionistas institucionales globales.  Se unió a AMC en su fase inicial, y es parte del equipo que apoyo su crecimiento de una puesta en marcha dentro de IFC hasta US $10 mil millones recaudados tras trece fondos.
                                Anteriormente, Colin fue socio de Duke Street, la firma de capital privado con sede en Londres, donde se centró principalmente en los sectores de servicios financieros y sector consumidor.  Antes de cumplir su MBA, Colin trabajó con Morgan Stanley en Nueva York y Celfin en Santiago de Chile.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 28******************************************/}
                <div className="modal_content" id={'modal_28'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/macarena-navarrete-91b2a06/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal28} src={cierre} alt="cierre" /></div>

                                <b>Macarena Navarrete</b>
                                <p>Country Managing Partner en EY Chile</p>
                                "El 1 de enero de 2018, Macarena asumió como Country Managing Partner de EY Chile.

                                Macarena forma parte del directorio de la Cámara Chileno Norteamericana de Comercio, de Inbest, del Círculo de Finanzas en Icare y del Consejo Asesor de Chile Transparente.  Ha sido elegida cinco veces como parte de las “100 Mujeres Líderes en Chile”.

                                Macarena es abogado, estudió en la Universidad de Chile, y antes de llegar a EY trabajó en reconocidos estudios de abogados, en las áreas de litigación en materia penal.

                                Su liderazgo ha sido fuertemente inclusivo, encabezando iniciativas con respecto a género y diversidad sexual, generaciones e inmigración, y personas con discapacidad. Además, Macarena está fomentando dentro de la firma un entorno de innovación y desarrollo digital.
                                "

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 29 ******************************************/}
                <div className="modal_content" id={'modal_29'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/jorge-arg%C3%BCello/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal29} src={cierre} alt="cierre" /></div>

                                <b>Jorge Argüello</b>
                                <p>Embajador argentino en los Estados Unidos de América</p>
                                El 6 de febrero de 2020, Jorge Argüello asumió el cargo de embajador argentino en los Estados Unidos de América. Jorge Argüello es un abogado y político.
                                El embajador inició su carrera política en 1987 cuando asumió como legislador de la Ciudad de Buenos Aires. Además, fue designado como diputado nacional en dos oportunidades: la primera durante la presidencia de Carlos Menem en 1991 y la segunda durante la presidencia de Néstor Kirchner en 2003. Jorge Argüello tiene amplios conocimientos en el ámbito político, ya que mientras fue diputado nacional también ocupó los cargos de presidente de la comisión de relaciones exteriores y presidente del Observatorio Parlamentario de la cuestión Malvinas. En 2018 fue secretario de estado para la cuestión Malvinas en la provincia de Tierra del Fuego, Antártida e Islas del Atlántico Sur.
                                Jorge Argüello ha ocupado diversos cargos diplomáticos como embajador argentino: ante las Naciones Unidas (2007-2011); en los Estados Unidos (2011-2012), y en Portugal y Cabo Verde (2013-2015). En 2014 creó la fundación Embajada Abierta, un centro de relaciones internacionales y políticas públicas. Jorge Argüello es miembro consejero del Consejo Argentino para las Relaciones Internacionales (CARI).
                                Asimismo, fue miembro titular del Center for Latin American Studies (CLAS) advisory board del School of Foreign Services en la Universidad de Georgetown, e investigador asociado del Centro de Estudios Internacionales (CEI) en la universidad ISCTE-IUL Portugal. Además, fue presidente de la carrera de relaciones internacionales y gobierno en la Universidad Argentina de la Empresa (UADE).
                                Fue nombrado embajador de los Estados Unidos por el presidente Alberto Fernández a principios de 2020.
                                Jorge Argüello está casado con Erika Grinberg y tiene 4 hijos.


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 30 ******************************************/}
                <div className="modal_content" id={'modal_30'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ivonnecuello/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal30} src={cierre} alt="cierre" /></div>

                                <b>Ivonne Cuello</b>
                                <p>CEO en LAVCA</p>
                                "Ivonne Cuello es la CEO de la Asociación para la Inversión de Capital Privado en América Latina (LAVCA), organización compuesta por más de 190 miembros con US$65b dirigidas a capitalizar crecer negocios latinoamericanos. Los miembros incluyen fondos de capital privado, Venture Capital, Real Assets (bienes raíces y de infraestructura más activos), así como Family Offices, fondos de pensión, sovereign wealth funds,  fondos soberanos, y otros inversionistas de capital privado. Entre sus funciones, Ivonne también se encargó de la creación y gestión de LAVCA | MIT Inside Private Equity, Deal Making in Latin America, un curso intensivo dirigido por profesores del MIT e inversionistas líderes en el sector.

                                Antes de unirse a LAVCA, Ivonne fue Directora de Emprendimiento en ANDI (Asociación Nacional de Empresarios de Colombia), una organización del sector privado con más de 1.100 miembros. En este cargo, dirigió y lanzó su Programa de Emprendimiento llamado ANDI del Futuro con 300 emprendedores de alto potencial.  Ivonne también trabajó anteriormente en Proexport Colombia, una agencia de inversión gubernamental donde dirigía relaciones con inversionistas de capital privado de EE. UU., Canadá, Reino Unido y Asia. Además, colaboró ​​con el Ministerio de Finanzas y un banco de desarrollo local en un esfuerzo por mejorar el marco regulatorio del capital privado. Ivonne estudió su Maestría en Ciencias en Global Management de la Universidad Paris-Dauphine y una Licenciatura en Economía de la Universidad de los Andes."

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 31 ******************************************/}
                <div className="modal_content" id={'modal_31'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/sarah-bennani-464990b/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal31} src={cierre} alt="cierre" /></div>

                                <b>Sarah Bennani</b>
                                <p>Investment Officer en IFC</p>
                                Sarah Bennani se desempeña como Investment Officer en IFC con sede en Washington DC, donde cubre fondos de Private Equity y Venture Capital para América Latina y el Caribe.

                                Comenzó su carrera en IFC en la oficina de Estambul durante 4 años, donde cubrió los nuevos y existentes fondos de negocios para Fondos de Private Equity del IFC. Antes de unirse a IFC en 2012, Sarah trabajó en gestión de activos, trabajando en Massena Partners, una multifamily office con sede en París y AGF PE (ahora Idinvest Partners), una firma europea de Private Equity y Venture Capital. Sarah ha supervisado inversiones de fondos en MENA, África, Rusia, Europa, Asia y América Latina.

                                Sarah tiene una maestría de ESCP Europe Business School en Francia.


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 32 ******************************************/}
                <div className="modal_content" id={'modal_32'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/mangarelli/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal32} src={cierre} alt="cierre" /></div>

                                <b>Eduardo Mangarelli</b>
                                <p>Presidente en Endeavor Uruguay / Socio IC Ventures</p>
                                "Eduardo Mangarelli es Ingeniero de Sistemas, Decano de la Facultad de Ingeniería de la Universidad ORT Uruguay, Presidente de Endeavor Uruguay e integrante del Directorio del Laboratorio Tecnológico del Uruguay. Se desempeñó anteriormente como Principal Software Engineer Lead de Microsoft a nivel global.

                                Es también socio y director de Tryolabs, Intermedia, MonkeyLearn y del fondo de inversión IC Ventures."

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 33 ******************************************/}
                <div className="modal_content" id={'modal_33'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ericmanlunas/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal33} src={cierre} alt="cierre" /></div>

                                <b>Eric Manlunas</b>
                                <p>Fundador y  Managing Partner de Wavemaker Partners</p>
                                Eric Manlunas es el fundador y Managing Partner de Wavemaker Partners, fondo de capital de riesgo internanacional fundado en 2003, y que tiene dos oficinas centrales en Los Ángeles y Singapur con más de $ 400 millones en activos bajo administración. Eric ha sido emprendedor en dos oportunidades y se ha convertido en inversionista de riesgo con una amplia trayectoria como inversionista en etapas tempranas en más de 350 startups. De las startups tecnológicas que creó una es un e-commerce, en 1996, y la otra es de servicios de Internet en 1999, ambas crecieron con éxito y finalmente se vendieron a compradores estratégicos. Eric comenzó su carrera como consultor asociado en la división de consultoría de gestión minorista de Arthur Andersen de 1991 a 1995. Obtuvo un MBA de la Universidad de Pepperdine en 1995 y una licenciatura en Comunicaciones de la Universidad Internacional de Florida en 1990.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 34 ******************************************/}
                <div className="modal_content" id={'modal_34'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/maria-de-los-angeles-romo-a1b7a323/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal34} src={cierre} alt="cierre" /></div>

                                <b>María de los Ángeles Romo</b>
                                <p>Gerenta Startup Chile</p>
                                Más de 20 años de experiencia en consultoría estratégica, financiamiento para empresas, incubación, aceleración de empresas, business angels y capital de riesgo. Fue Directora Ejecutiva de Endeavor en Chile, Directora de Mujeres Empresaria, Broota, Inria Chile, entre otros. Se desempeñó como Gerente Corporativa de Corfo y como Directora de Estrategia. Adicionalmente es Directora de la empresa pública Econssa S.A. y Lapsa S.A., miembro del comité estratégico de las empresas DCanje y E;Brown. Profesora del curso Emprendimientos Tecnológicos del MBA de la PUC y del Master en Innovación de la misma Universidad. Ingeniero Forestal de la Universidad Católica de Chile, Máster en Finanzas de la Universidad Adolfo Ibáñez, Certificado en Administración de Fondos de Inversión (Venture Capital) de la Universidad Adolfo Ibáñez y especializaciones en liderazgo y emprendimiento Stanford University, San Francisco; Harvard Business School, Boston y Columbia Business School NY. Cuatro veces elegida Mujeres Líderes por El Mercurio.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 35 ******************************************/}
                <div className="modal_content" id={'modal_35'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/patriciapastorg/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal35} src={cierre} alt="cierre" /></div>

                                <b>Patricia Pastor</b>
                                <p>Directora General en GoHub by Global Omnium & General Partner en GoHub Ventures</p>
                                Directora General de GoHub by Global Omnium y General Partner de GoHub Ventures. Con una gran experiencia en el área del emprendimiento, durante varios años fue la responsable del Ayuntamiento de la ciudad de Valencia para el desarrollo del ecosistema y creó la primera comunidad de startups pública de España con 5.000 miembros. En todo este tiempo, se ha convertido en una figura muy valorada en el sector por ayudar a crear una comunidad cohesionada. Anteriormente fue emprendedora y trabajó en varias aceleradoras.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 36 ******************************************/}
                <div className="modal_content" id={'modal_36'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/carmen-correa-78594724/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal36} src={cierre} alt="cierre" /></div>

                                <b>Carmen Correa</b>
                                <p>COO en Promujer & Board Member en Pymecapital</p>
                                Carmen Correa es Directora de Operaciones de Pro Mujer. Pro Mujer es una organización líder en América Latina que apoya el desarrollo de mujeres dándoles acceso a préstamos, servicios de salud y educación. Durante el año 2015 y 2016 Carmen fue contratada por la Agencia Nacional de Investigación e Innovación (ANII) en Uruguay para desarrollar un nuevo instrumento que permitiera financiar un fondo de capital de riesgo y una aceleradora de empresas. La ANII es una entidad gubernamental que promueve la investigación y la aplicación de nuevos conocimientos a la realidad productiva y social del país. De enero 2010 a mayo 2015 Carmen fue responsable de Fundación AVINA Uruguay y dirigió el equipo de Mercados Inclusivos de AVINA a nivel continental. AVINA es una Fundación que apoya el desarrollo sostenible en América Latina, promoviendo alianzas entre líderes sociales y empresariales. Del 2005 al 2009 Carmen fue la Directora Ejecutiva de Endeavor Uruguay y del 2000 al 2004 fue la Directora de Operaciones de la organización. Endeavor es una organización internacional sin fines de lucro que apoya emprendedores de alto impacto. De 1999 al 2000 Carmen fue la Gerente Administrativa de DeRemate.com en Uruguay, un sitio web de ventas en línea. De 1992 a 1999 Carmen ocupó varias posiciones administrativas en el Banco Interamericano de Desarrollo (BID) en Washington DC. De 1990 a 1992 trabajó para la Organización de Estados Americanos (OEA) en Uruguay. Carmen tiene un BS (Licenciatura en Ciencias) en Administración de la National- Louis University en McLean, Virginia, tiene un Certificado de Postgrado en Administración de Georgetown University, entre otros cursos de desarrollo gerencial de John Hopkins University en Washington DC, USDA en Washington DC y ESADE en Uruguay. Carmen está casada y tiene 3 hijos.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 37 ******************************************/}
                <div className="modal_content" id={'modal_37'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/carmen-correa-78594724/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal37} src={cierre} alt="cierre" /></div>

                                <b>Saul Singer</b>
                                <p>Co-author  “Start-Up Nation: The Story of Israel's Economic Miracle”</p>
                                "Saul Singer es co-autor, con Dan Senor, del best seller “Start-Up Nation: The Story of Israel's Economic Miracle”. El libro estuvo en la lista de best sellers del New York Times y del Wall Street Journal, además de haber sido traducido a más de 20 idiomas.

                                Singer se desempeñó como asesor del Comité del Senado sobre Banca, Vivienda y Asuntos Urbanos de los Estados Unidos, y en el Comité de Asuntos Exteriores de la Cámara de Representantes antes de convertirse en columnista y escritor de importantes publicaciones.

                                Singer dicta conferencias a nivel mundial relacionadas con la innovación, en las que explica cómo Israel y otros países han logrado transformar nuestras vidas en diferentes ámbitos: salud, educación y ciudades.

                                Es miembro de varias juntas directivas, incluidas “Innovation and Science Australia”, organismo de supervisión del gobierno, “Vintage Investment Partners”, el único fondo de fondos de Israel, y “Tevel B'tsedek”, una ONG israelí que trabaja en Nepal y otros países.

                                Singer nació en Nueva York y actualmente vive en Jerusalén con su esposa Wendy y sus tres hijas.
"

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 38 ******************************************/}
                <div className="modal_content" id={'modal_38'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/alfonsoamat/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal38} src={cierre} alt="cierre" /></div>

                                <b>Alfonso Amat</b>
                                <p>Head of Startup Business Development - LatAm en Amazon Web Services (AWS)</p>
                                "Actualmente se desarrolla como Responsable del Desarrollo del Ecosistema Emprendedor para América Latina en Amazon Web Services. (AWS)

                                Emprendedor exitoso que fundó, desarrolló en LATAM y finalmente vendió su Startup a Globant. Intrapreneur en grandes corporaciones como Accenture y Telefónica/Movistar gestionando Innovación y proyectos de transformación digital.

                                Su carrera se desarrolló en Alemania, Argentina, Brasil, Chile, España, Holanda, Inglaterra, Suiza y Uruguay.
                                "

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 39 ******************************************/}
                <div className="modal_content" id={'modal_39'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="hhttps://www.linkedin.com/in/alejandro-mashad-329174/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal39} src={cierre} alt="cierre" /></div>

                                <b>Alejandro Mashad</b>
                                <p>Director del Centro de Entrepreneurship de la Universidad de San Andrés</p>
                                "Es Director del Centro de Entrepreneurship de la Universidad de San Andrés y profesor de la materia. Hasta 2011 fue Director Ejecutivo de la Fundación Endeavor Argentina, organización internacional que apoya el desarrollo de los emprendedores y su ecosistema.

                                Alejandro es mendocino, Ingeniero Civil recibido con honores en la Universidad Nacional de Cuyo. Becado por la Fundación Reuters, estudió un MBA en IESE (Universidad de Navarra, Barcelona) y en Kellogg School of Management (Northwestern University). Se desempeñó durante casi 4 años en The Boston Consulting Group como consultor. Hasta Oct 2017 fue Director del Centro de Emprendedores de UMET (Universidad Metropolitana para la Educación y el Trabajo), primera Universidad del país en establecer al entrepreneurship como transversal y obligatorio para todas las carreras.

                                Realiza esporádicamente actividades como profesor invitado en materias relacionadas con la temática emprendedora y como disertante en eventos relacionados con el tema. Es además participante de directorios de empresas de emprendedores.
                                "
                                "


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 40 ******************************************/}
                <div className="modal_content" id={'modal_40'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/meghansk/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal40} src={cierre} alt="cierre" /></div>

                                <b>Meghan Stevenson-Krausz</b>
                                <p>Directora de INCA Ventures</p>
                                "Meghan Stevenson-Krausz es la Directora de INCA Ventures, un fondo de Venture Capital enfocado en Fintech y basado en Lima, Peru. Antes de INCA, vivía en San Francisco, donde trabajaba entre los mundos de ONG y Venture Capital. Ahora esta contenta de traer su experiencia para apoyar a fundadores en América Latina y busca crear un sector de capital de riesgo más diverso e inclusivo en Latam. También es miembro de la Junta Fundadora de WeInvest, la primera comunidad para mujeres que invierten sobre toda América Latina."

                            </div>
                        </div>
                    </div>
                </div>


                {/***************************** MODAL 42 ******************************************/}
                <div className="modal_content" id={'modal_42'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/francisco-guzm%C3%A1n-64277513/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal42} src={cierre} alt="cierre" /></div>

                                <b>Francisco Guzmán</b>
                                <p>Vice presidente ACVC</p>
                                Socio de Carey, la oficina de abogados más grande de Chile, y Director de la Asociación Chilena de Venture Capital. En Carey es uno de los abogados a cargo de la práctica de Fondos, Venture Capital y Private Equity.Francisco es abogado de la Universidad Católica y LL.M de Columbia University (James Kent Scholar, máxima distinción otorgada por la Escuela de Derecho) (2010). Está admitido para ejercer como abogado en Chile y en Nueva York. Autor del libro “Información privilegiada en el mercado de valores”, LexisNexis (2007), 2ª edición (2009). Es director del SOFOFA HUB (Hub Tecnológico de la Federación de las Industrias) y de la Aceleradora de Negocios de Mujeres Empresarias. Francisco ha sido reconocido como un abogado líder en Venture Capital por The Legal 500 (leading individual), Leaders League y por Best Lawyers. También fue reconocido como Mejor Abogado Individual en Fondos Privados en Chile por Client Choice (2020).


                            </div>
                        </div>
                    </div>
                </div>




                {/***************************** MODAL 43 ******************************************/}
                <div className="modal_content" id={'modal_43'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/andres-ackermann-cfa-7232116/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal43} src={cierre} alt="cierre" /></div>

                                <b>Andrés Ackermann</b>
                                <p>Principal Investment Officer - Head of Investment Funds en IDB Invest</p>
                                "Andrés es Head of Investment Funds en BID Invest, a la que se incorporó en 2016. Se especializa en Mercados Financieros. Actualmente, es el responsable de las inversiones que realiza la organización, a través de fondos de inversión, que incluyen private equity, private credit y venture capital en América Latina y el Caribe.

                                Andrés es miembro de varios Limited Partner Advisory Committees en los que BID Invest posee capital.

                                Antes de unirse al Grupo BID, se desempeñó como Corporate Banking Manager and Corporate Finance/Capital Markets Manager en Citibank en Bolivia y como Middle-Market Banking Manager and Financial Planning Manager en Banco de Crédito, también en Bolivia.

                                Andrés obtuvo una maestría en finanzas de Boston College y una maestría en
                                economía de la Universidad Internacional de Florida (EE. UU.). También es CFA Charterholder.
"


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 44 ******************************************/}
                <div className="modal_content" id={'modal_44'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ignaciohecquet/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal44} src={cierre} alt="cierre" /></div>

                                <b>Ignacio Hecquet</b>
                                <p>"Socio en EY | Transaction Advisory Services para Argentina, Uruguay y Paraguay "</p>
                                "Ignacio Hecquet lidera la práctica de Strategy and Transactions, a través de la cual EY asesora clientes en temas relacionados con Strategy, M&A, Due Diligence, Corporate Finance, Valuation, Business Modeling y Operational Transactional Services.

                                Ignacio ha liderado en los últimos años numerosos trabajos de consultoría en transacciones en Latinoamérica para corporaciones multinacionales y firmas de Private Equity y Venture Capital.

                                Anteriormente, se desempeñó como socio de Assurance a cargo del sector de Tecnología, Medios y Telecomunicaciones. En este rol ha dirigido numerosos proyectos de salida a oferta pública en Argentina y USA, auditoría externa y gestión de riesgos.

                                Ignacio es Contador Público de la Universidad de Buenos Aires. Actualmente es profesor en la Universidad Di Tella y en la Universidad de Buenos Aires. Adicionalmente, es mentor de Endeavor."
                            </div>
                        </div>
                    </div>
                </div>
                {/***************************** MODAL 45 ******************************************/}
                <div className="modal_content" id={'modal_45'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/javierartigasherrera/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal45} src={cierre} alt="cierre" /></div>

                                <b>Javier Artigas Herrera</b>
                                <p>CEO y Founder at  Connectus Medical Group</p>
                                Javier es uruguayo, presidente de las compañías Connectus Medical Group, UMBRA Visual Intelligence y MobilCue Ltd. todas con sede en Israel. Como paciente con insuficiencia renal crónica y más tarde, trasplantado renal, trabaja  para  el programa MIT Solve del Massachussets Institute of Technology buscando soluciones para enfermedades crónicas a través de machine learning y emociones. Escribió el libro “The Book Israel – Uruguay: El legado de los Emprendedores” apoyado por la Embajada de Israel en Uruguay y la Cámara de Representantes de Uruguay. Hoy está escribiendo su segundo libro. Ha sido ganador de premios tales como:  Premio MIT Technology Review, Premio Startup Nation Jerusalem, mención especial del Copenhagen Institute for Futures Studies de Dinamarca, entre otros.  Conferencista en innovación para el BID, MIT, Instituto Karolinska, Universidad de Sodertorn, Universidad Hebrea de Jerusalem,  Universidad Católica de Córdoba Argentina, Universidad de Católica de Uruguay, Universidad Tecnológica de Honduras, Universidad de Montevideo, Universidad ORT del uruguay: Ha participado en varias charlas TEDx. Ha sido destacado en conferencias por el Premio Nobel de Economía Alvin Roth como agente de cambio en la nueva economía colaborativa. Es autor del programa UMBRA:  ¨La verdad detrás de la verdad¨ Microexpresiones y codificación facial, aplicado por organismos de seguridad, civil y militar en Israel y otros países. La historia de su vida, junto al infarto de Hernán Casciari  y la llegada de Joe Gebbia -co-fundador de Airbnb- (a su casa en Montevideo) fue adquirida por Disney Globall para ser llevada al cine.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 46 ******************************************/}
                <div className="modal_content" id={'modal_46'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/francisco-guzm%C3%A1n-64277513/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal46} src={cierre} alt="cierre" /></div>

                                <b>Francisco Guzmán</b>
                                <p>Vice presidente ACVC</p>
                                Socio de Carey, la oficina de abogados más grande de Chile, y Director de la Asociación Chilena de Venture Capital. En Carey es uno de los abogados a cargo de la práctica de Fondos, Venture Capital y Private Equity.Francisco es abogado de la Universidad Católica y LL.M de Columbia University (James Kent Scholar, máxima distinción otorgada por la Escuela de Derecho) (2010). Está admitido para ejercer como abogado en Chile y en Nueva York. Autor del libro “Información privilegiada en el mercado de valores”, LexisNexis (2007), 2ª edición (2009). Es director del SOFOFA HUB (Hub Tecnológico de la Federación de las Industrias) y de la Aceleradora de Negocios de Mujeres Empresarias. Francisco ha sido reconocido como un abogado líder en Venture Capital por The Legal 500 (leading individual), Leaders League y por Best Lawyers. También fue reconocido como Mejor Abogado Individual en Fondos Privados en Chile por Client Choice (2020).
                            </div>
                        </div>
                    </div>
                </div>


                {/***************************** MODAL 47 ******************************************/}
                <div className="modal_content" id={'modal_47'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    {/* <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/francisco-guzm%C3%A1n-64277513/"></a> */}
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal47} src={cierre} alt="cierre" /></div>

                                <b>Yoed Magen</b>
                                <p>Embajador del Estado de Israel en Uruguay </p>
                                El Embajador Yoed Magen, diplomático de carrera en el Servicio Exterior de Israel, ha sido director para América Central, México y el Caribe del Ministerio de Relaciones Exteriores.

                                Yoed Magen se unió al Ministerio de Relaciones Exteriores en agosto de 1992. Posteriormente se desempeñó en misiones en el extranjero como miembro de la Delegación de Israel ante la 47° Asamblea General de las Naciones Unidas (1993), Segundo Secretario de la Embajada de Israel en San José, Costa Rica (1994-1997). Consejero de la Embajada de Israel en la Ciudad de México, México (1997-2000); Jefe Adjunto de Misión en la Embajada de Israel en Nueva Delhi, India (2003-2007); Embajador en Panamá (2009-2011) y Embajador en Colombia (2011-2014).

                                En la sede del Ministerio de Relaciones Exteriores en Jerusalén, el Embajador Magen, ocupó cargos en la División Latinoamericana, División Económica y en la División de América del Norte, se desempeñó como Secretario del Comité Intergubernamental sobre los judíos desaparecidos en Argentina y como Asesor de Políticas para la División Estratégica en la rama de Planificación de las Fuerzas de Defensa de Israel.

                                Yoed Magen tiene una Licenciatura en Ciencias Políticas y Criminología de la Universidad Bar-Ilan y es Ingeniero en Programación de O.R.T. Singalowsky.

                                El Embajador Magen está casado con la Sra. Adi y tienen tres hijos.

                            </div>
                        </div>
                    </div>
                </div>



                {/***************************** MODAL 49 ******************************************/}
                <div className="modal_content" id={'modal_49'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/pjamini/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal49} src={cierre} alt="cierre" /></div>

                                <b>Paimun Amini</b>
                                <p>Director de Inversiones de VC en Leaps de Bayer</p>
                                Como Director de Inversiones de VC para Leaps de Bayer, Paimun Amini impulsa la estrategia de inversión que acelera las innovaciones revolucionarias en la alimentación y la agricultura. Con una formación científica y de vanguardia en innovación digital, Paimun Amini y sus colegas han trabajado con cientos de empresas con el potencial de trasladar la agricultura del enfoque de simplemente producir MÁS a producir MEJOR. Paimun Amini ha liderado inversiones en Sudamérica, África y Asia; más recientemente se unió a los directorios de Unfold Bio y Apollo Agriculture. Se incorporó a Bayer en 2011 y ha ocupado varios puestos de liderazgo en organizaciones de R&D y IT; antes de lo cual estaba realizando una investigación para el USDA. Paimun Amini tiene una licenciatura en biología y un MBA de la Universidad de Washington en St. Louis.
                            </div>
                        </div>
                    </div>
                </div>


                {/***************************** MODAL 50 ******************************************/}
                <div className="modal_content" id={'modal_50'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/matiaspeire/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal50} src={cierre} alt="cierre" /></div>

                                <b>Matías Peire</b>
                                <p>Managing Partner en GRIDX</p>
                                Más de 18 años de experiencia en la creación y gestión de empresas de alta tecnología.
                                En 2013, luego de vender su participación en 3Way Solutions, comenzó un proceso de investigación para desarrollar un modelo que vincule a la ciencia con el mundo productivo. Luego de tres años, fundó GRIDX, un company builder que desarrolla nuevas empresas de base científica, (específicamente en biotecnología) armando equipos con científicos y emprendedores de negocios e invirtiendo en las mismas para su lanzamiento al mercado. Desde su inicio formal en 2017, GRIDX creó e invirtió en 22 startups biotecnológicas de Argentina y Uruguay y en la actualidad administra USD 17MM para crear un portfolio de 30 empresas. Es licenciado en Administración de empresas de la UBA y Master en Finanzas de la Universidad de San Andrés. En 2015 participó del programa ejecutivo de SIngularity University. Es parte del consejo de la ONG Educar2050.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 51 ******************************************/}
                <div className="modal_content" id={'modal_51'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/rodrigo-villar-9b9969/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal51} src={cierre} alt="cierre" /></div>


                                <b>Rodrigo Villar</b>
                                <p>Socio Fundador en New Ventures y Adobe Capital</p>
                                Rodrigo Villar esta comprometido con transformar la manera tradicional de hacer negocios y crear un nuevo modelo empresarial que persiva el impacto como status quo. Como emprendedor social y Fundador de New Ventures, ha logrado consolidar un equipo de
                                personas que continuan innovando en el sector de Inversión de Impacto.

                                Reconocido como pionero en el ecosistema de emprendimiento social, desde 2004, Rodrigo y New Ventures han logrado impulsar soluciones disruptivas que desafían el status quo y contribuyen a crear la sociedad en la que deseamos vivir. Con esta misión en mente y en colaboración con el sector público y privado, New Ventures gestiona diferentes programas de aceleración, opera un fondo de inversión de impacto, Adobe Capital y una financiera VIWALA. Además, organiza anualmente el evento más importante de inversión de impacto en la región: FLII.

                                Rodrigo fue seleccionado como Ashoka Fellow y es ampliamente reconocido como una de las principales figuras del país en materia de emprendimiento social y ambiental. Es el presidente de la Alianza por la Inversión de Impacto en México, participó como miembro del comité ejecutivo de la Red de Aspen para Emprendedores en Desarrollo (ANDE), Iniciativa
                                México y CI Banco.

                                Rodrigo cuenta con un MBA del Royal Melbourne Institute of Technology y estudió la carrera de Contabilidad y Administración Financiera por el Tecnológico de Monterrey.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 52 ******************************************/}
                <div className="modal_content" id={'modal_52'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/quingarcia/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal52} src={cierre} alt="cierre" /></div>


                                <b>Quin García</b>
                                <p>Managing Director en Autotech Ventures, Mobility & Transportation Venture Capital Investor</p>
                                Criado en Silicon Valley, Quin es un “chico de los autos” de toda la vida que ha pasado su carrera resolviendo problemas de transporte con tecnología. Ha dedicado su carrera a usar la tecnología para crear un transporte seguro, equitativo, conveniente y limpio para todos. Quin aporta a Autotech la pasión por permitir el éxito de los emprendedores de la movilidad al asesorarlos sobre el desarrollo empresarial, el reclutamiento, la recaudación de fondos y la diferenciación competitiva.
                                Además de su trabajo con las compañías de la cartera de Autotech Ventures, Quin se ha desempeñado a título personal como Director de la Junta, Observador de la Junta y miembro de la Junta Asesora en numerosas empresas emergentes de movilidad, incluidas Lyft, Peloton Technology y Connected Signals. Formó parte del equipo fundador de la startup de infraestructura de vehículos eléctricos Better Place, responsable de las asociaciones con fabricantes de automóviles y proveedores de piezas mientras vivía en Israel, Japón y China.
                                Quin tiene una maestría en Ciencias de la Administración e Ingeniería Automotriz de Stanford y una licenciatura con altos honores en Economía Aplicada y Administración de Cornell.
                                Quin habla inglés, español y chino y disfruta conduciendo autos de carrera.

                            </div>
                        </div>
                    </div>
                </div>

            </section >
        )
    }
}

export default Modals;