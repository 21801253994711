import React from 'react';
import logo_fics from "../../../assets/img/logo-fics.png";


class Acerca extends React.Component {
    render() {
        return (
            <section className="acerca">
                <div className="acerca_max">
                    <hr/>
                    <div className="acerca_contenedor">
                        <div className="acerca_info">
                            <img src={logo_fics} alt="fics"/>
                            About the <b>Cono Sur</b> <br/> Investment Forum
                        </div>

                        <div className="texto_acerca">
                            <h3>The leading Forum on the Private Equity, Venture and Seed Capital Industry in the Southern Cone. </h3> <br/>
                            <p>Organized by the <b>Chilean Venture Capital Association (ACVC), the Argentine Association of Private Equity, Venture and Seed Capital (ARCAP), and the Investment, Exports and Country Brand Promotion Agency, Uruguay XXI</b>, FICS 2020 will bring together the most relevant actors in this industry to discuss trends and investment opportunities and offer a global view on the development opportunities of the sector.

                        
                             
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Acerca;