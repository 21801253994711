import React from 'react';
import cierre from "../../../assets/img/agenda/boton_cierre.png";



class Modals extends React.Component {
    show_modal1 = () => {
        const modal_1 = document.querySelector('#modal_1');
        const body = document.querySelector('body');
        modal_1.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal2 = () => {
        const modal_2 = document.querySelector('#modal_2');
        const body = document.querySelector('body');
        modal_2.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal3 = () => {
        const modal_3 = document.querySelector('#modal_3');
        const body = document.querySelector('body');
        modal_3.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal4 = () => {
        const modal_4 = document.querySelector('#modal_4');
        const body = document.querySelector('body');
        modal_4.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal5 = () => {
        const modal_5 = document.querySelector('#modal_5');
        const body = document.querySelector('body');
        modal_5.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal6 = () => {
        const modal_6 = document.querySelector('#modal_6');
        const body = document.querySelector('body');
        modal_6.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal7 = () => {
        const modal_7 = document.querySelector('#modal_7');
        const body = document.querySelector('body');
        modal_7.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal8 = () => {
        const modal_8 = document.querySelector('#modal_8');
        const body = document.querySelector('body');
        modal_8.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal9 = () => {
        const modal_9 = document.querySelector('#modal_9');
        const body = document.querySelector('body');
        modal_9.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal10 = () => {
        const modal_10 = document.querySelector('#modal_10');
        const body = document.querySelector('body');
        modal_10.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal11 = () => {
        const modal_11 = document.querySelector('#modal_11');
        const body = document.querySelector('body');
        modal_11.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal12 = () => {
        const modal_12 = document.querySelector('#modal_12');
        const body = document.querySelector('body');
        modal_12.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal13 = () => {
        const modal_13 = document.querySelector('#modal_13');
        const body = document.querySelector('body');
        modal_13.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal14 = () => {
        const modal_14 = document.querySelector('#modal_14');
        const body = document.querySelector('body');
        modal_14.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal15 = () => {
        const modal_15 = document.querySelector('#modal_15');
        const body = document.querySelector('body');
        modal_15.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal16 = () => {
        const modal_16 = document.querySelector('#modal_16');
        const body = document.querySelector('body');
        modal_16.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal17 = () => {
        const modal_17 = document.querySelector('#modal_17');
        const body = document.querySelector('body');
        modal_17.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal18 = () => {
        const modal_18 = document.querySelector('#modal_18');
        const body = document.querySelector('body');
        modal_18.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal19 = () => {
        const modal_19 = document.querySelector('#modal_19');
        const body = document.querySelector('body');
        modal_19.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal20 = () => {
        const modal_20 = document.querySelector('#modal_20');
        const body = document.querySelector('body');
        modal_20.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal21 = () => {
        const modal_21 = document.querySelector('#modal_21');
        const body = document.querySelector('body');
        modal_21.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal22 = () => {
        const modal_22 = document.querySelector('#modal_22');
        const body = document.querySelector('body');
        modal_22.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal23 = () => {
        const modal_23 = document.querySelector('#modal_23');
        const body = document.querySelector('body');
        modal_23.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    show_modal24 = () => {
        const modal_24 = document.querySelector('#modal_24');
        const body = document.querySelector('body');
        modal_24.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    show_modal25 = () => {
        const modal_25 = document.querySelector('#modal_25');
        const body = document.querySelector('body');
        modal_25.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal26 = () => {
        const modal_26 = document.querySelector('#modal_26');
        const body = document.querySelector('body');
        modal_26.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal27 = () => {
        const modal_27 = document.querySelector('#modal_27');
        const body = document.querySelector('body');
        modal_27.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal28 = () => {
        const modal_28 = document.querySelector('#modal_28');
        const body = document.querySelector('body');
        modal_28.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal29 = () => {
        const modal_29 = document.querySelector('#modal_29');
        const body = document.querySelector('body');
        modal_29.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal30 = () => {
        const modal_30 = document.querySelector('#modal_30');
        const body = document.querySelector('body');
        modal_30.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal31 = () => {
        const modal_31 = document.querySelector('#modal_31');
        const body = document.querySelector('body');
        modal_31.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    show_modal32 = () => {
        const modal_32 = document.querySelector('#modal_32');
        const body = document.querySelector('body');
        modal_32.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal33 = () => {
        const modal_33 = document.querySelector('#modal_33');
        const body = document.querySelector('body');
        modal_33.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal34 = () => {
        const modal_34 = document.querySelector('#modal_34');
        const body = document.querySelector('body');
        modal_34.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal35 = () => {
        const modal_35 = document.querySelector('#modal_35');
        const body = document.querySelector('body');
        modal_35.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal36 = () => {
        const modal_36 = document.querySelector('#modal_36');
        const body = document.querySelector('body');
        modal_36.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal37 = () => {
        const modal_37 = document.querySelector('#modal_37');
        const body = document.querySelector('body');
        modal_37.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal38 = () => {
        const modal_38 = document.querySelector('#modal_38');
        const body = document.querySelector('body');
        modal_38.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal39 = () => {
        const modal_39 = document.querySelector('#modal_39');
        const body = document.querySelector('body');
        modal_39.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal40 = () => {
        const modal_40 = document.querySelector('#modal_40');
        const body = document.querySelector('body');
        modal_40.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal41 = () => {
        const modal_41 = document.querySelector('#modal_41');
        const body = document.querySelector('body');
        modal_41.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal42 = () => {
        const modal_42 = document.querySelector('#modal_42');
        const body = document.querySelector('body');
        modal_42.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal43 = () => {
        const modal_43 = document.querySelector('#modal_43');
        const body = document.querySelector('body');
        modal_43.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal44 = () => {
        const modal_44 = document.querySelector('#modal_44');
        const body = document.querySelector('body');
        modal_44.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal45 = () => {
        const modal_45 = document.querySelector('#modal_45');
        const body = document.querySelector('body');
        modal_45.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal46 = () => {
        const modal_46 = document.querySelector('#modal_46');
        const body = document.querySelector('body');
        modal_46.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal47 = () => {
        const modal_47 = document.querySelector('#modal_47');
        const body = document.querySelector('body');
        modal_47.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal48 = () => {
        const modal_48 = document.querySelector('#modal_48');
        const body = document.querySelector('body');
        modal_48.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal49 = () => {
        const modal_49 = document.querySelector('#modal_49');
        const body = document.querySelector('body');
        modal_49.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal50 = () => {
        const modal_50 = document.querySelector('#modal_50');
        const body = document.querySelector('body');
        modal_50.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal51 = () => {
        const modal_51 = document.querySelector('#modal_51');
        const body = document.querySelector('body');
        modal_51.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal52 = () => {
        const modal_52 = document.querySelector('#modal_52');
        const body = document.querySelector('body');
        modal_52.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    render() {
        return (
            <section className="modal">
                {/***************************** MODAL 1 *******************************************/}
                <div className="modal_content" id={'modal_1'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    {/* <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.google.com/"></a> */}
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal1} src={cierre} alt="cierre" /></div>

                                <b>Alejandro Sorgentini</b>
                                <p>President | ARCAP</p>
                                Alejandro Sorgentini is a founding partner of Victoria Capital Partners, a regionally dedicated private equity firm, and the successor to DLJ South American Partners. He joined DLJMB in 2000 as an Associate in Buenos Aires, Argentina, where he was dedicated to the firm’s private equity activities in South America.  Prior to joining DLJMB, Mr. Sorgentini held various positions with Motorola Inc. in Argentina, where he focused on the management of the company’s cellular operations in Argentina, Chile, Paraguay, Uruguay and Bolivia.  He graduated with a degree in Industrial Engineering from Universidad Catolica Argentina and received an M.B.A. from Harvard Business School.  He served on the board of directors of Cameo Chile S.A., Vitopel S.A. Peñaflor S.A., Trapiche S.A., Torneos y Competencias S.A., International Health Services Argentina S.A., Prensiplast S.A., PAB S.A. ,PABSA S.A. and Grupo Santillana de Ediciones S.L. He currently serves as a board member at Energy Fitness Clubs SpA., Grupo Los Grobo S.A. and Satus Ager S.A
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 2 *******************************************/}
                <div className="modal_content" id={'modal_2'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/andr%C3%A9s-meirovich-bellolio-20b2b29/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal2} src={cierre} alt="cierre" /></div>

                                <b>Andrés Meirovich</b>
                                <p>President | ACVC</p>
                                President of the Chilean Venture Capital Association (ACVC), and Managing Partner of Genesis Ventures. Doctor of Business Administration (c) SDA Bocconi, Italy. MBA from MIT, USA. Engineer Commercial and Master in Finance from the Universidad del Desarrollo.

                                Participated in the Executive Program in Entrepreneurial Innovation from Stanford University. Columnist of Diario Financiero since year 2013. In 2010 he received the award for outstanding alumni for the Universidad del Desarrollo, and the award for young leaders in 2012 granted by Diario Financiero. Director of Amipass, Predictable Media and Wenu Work. Director of Sofofa. Andrés has seen more than 400 projects, and has more than 10 years of experience in Venture Capital. Since 2018 he is Professor of the MBA of the University Adolfo Ibáñez.


                            </div>
                        </div>
                    </div>
                </div>
                {/***************************** MODAL 3 *******************************************/}
                <div className="modal_content" id={'modal_3'}>
                    <div className="modal">
                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ines-bonicelli-4872119/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal3} src={cierre} alt="cierre" /></div>

                                <b>Inés Bonicelli </b>
                                <p>Deputy Executive Director | Uruguay XXI</p>
                                Inés Bonicelli took office as Deputy Executive Director of Uruguay XXI in April 2020.

                                She holds an MBA with a focus on Finance from Michigan Business School, a degree in Business Administration from Universidad Católica del Uruguay and a diploma in Economics from Instituto de Economía de Montevideo.

                                She is also the founder and director of Atlantika Group, a one-stop-shop service consultancy for foreign real estate investors looking to set up or invest in Uruguay. In this role she developed strategies to present Uruguay as an investor destination and to obtain tax residency, and has been responsible for attracting and serving foreign clients.

                                She was also a partner of Bado y Asociados Sotheby’s International Realty, as well as founder and executive director of ReachingU - a Foundation for Uruguay, the U.S.-based non-profit organization that finances and supports educational projects in Uruguay, which has channeled more than USD 15 million.

                                Previously she was Operations Manager at Imperio Jp (New York, USA) and Operations Manager at Banco Bozano Simonsen (Rio de Janeiro, Brazil).

                                She also stands out for his volunteer activities at ReachingU, following the Providencia School project, Liceo Jubilar, Techo, and for organizing volunteer projects for young people and students.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 4 *******************************************/}
                <div className="modal_content" id={'modal_4'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/luisfelipeolivadiaz/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal4} src={cierre} alt="cierre" /></div>

                                <b>Luis Felipe Oliva</b>
                                <p>Head of Investments and Financing Division | Corfo</p>
                                Head of Investments and Financing Division at Corfo
                                Industrial Civil Engineer from the University of Chile and Master in Economics from the New York University. Currently, he serves as the Head of Investments and Financing Division at Corfo. He has more than 20  years of experience in the financial industry. He was CEO of MBI Fund Management Company. Also CEO of Zurich Investments, member of the Zurich Financial Services Group and deputy manager of Investment Strategies at Chilena Consolidada Insurance Company. He was also responsible for the trading desk at Zurich Fund Management Company. Previously, he worked in the investment area at Cuprum Pension Fund and in the Wealth Management division at Citibank in Chile. Additionally, he is part-time professor at the Faculty of Economics and Business of the University of Chile.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 5 *******************************************/}
                <div className="modal_content" id={'modal_5'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/martinbenitezaramendia/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal5} src={cierre} alt="cierre" /></div>

                                <b>Martin Benitez</b>
                                <p>IT Investment Specialist | Uruguay XXI</p>
                                Martín Benítez, has a degree in Business Administration from UCUDAL, and has a specialization in eCommerce from the University of León. He also studied Marketing and Management at New York University and Stanford. With experience in various e-commerce sites, he has worked for Saxo Bank as CMO for Latin America and companies related to Blockchain and Cryptocurrencies. In 2017 he launched the educational portal Bitcoinsycriptos.com and in 2018 he inaugurated the first Cryptocurrency Exchange in Uruguay. Since 2018 he is a teacher of the Cryptocurrency Course at the UCUDAL Faculty of Business Sciences. He is currently a Specialist in Technology companies of the Uruguay XXI Investment Team.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 6 *******************************************/}
                <div className="modal_content" id={'modal_6'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ramiro-lauzan-2a5181/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal6} src={cierre} alt="cierre" /></div>

                                <b>Ramiro Lauzan</b>
                                <p>Partner | L Catterton</p>
                                Mr. Lauzan is a Partner at L Catterton, focused in Latin America. Prior to joining L Catterton, he was a Managing Partner at Humus Capital Partners, an independent middle-market private equity firm he co-founded in South America, with investments in consumer goods, biotechnology and renewable energy. Previously, Mr. Lauzan spent over 8 years at McKinsey & Company, where he served clients across multiple sectors in Latin America, Asia and Australia. Prior to that, he also worked as an investment banker at Lehman Brothers, and in venture capital at L.I.D. Group. Mr. Lauzan is a member of the Board of Directors of Biosidus Group and Embotelladora Metropolitana. Mr. Lauzan holds a Business Economics degree with honors from Universidad Torcuato Di Tella in Argentina, and an MBA from Columbia Business School(Yosaji Morita scholar).


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 7 ******************************************/}
                <div className="modal_content" id={'modal_7'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/jeronimo-bosch-42a224/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal7} src={cierre} alt="cierre" /></div>

                                <b>Jerónimo Bosch</b>
                                <p>Managing Partner | Grupo Pegasus</p>
                                Jerónimo Bosch is President and Managing Partner of Grupo Pegasus, a firm based in Argentina and Colombia, dedicated to the creation and development of long term businesses in Latin America.  Pegasus has invested in commercial real estate, retail, financial services, technology, agribusiness, senior living and hostals operations, in companies and projects with a presence in Latin America and the US.  Mr. Bosch is Executive Director of ARG Realty and Director of Farmacity and Freddo.

                                Mr. Bosch has over 24 years of experience in real estate, private equity and venture capital investments, in Latin America and the US.  Before joining Pegasus in 2003, he lived eight years in New York (USA), São Paulo (Brazil) and San Francisco (USA), working at Morgan Stanley and Salomon Brothers, in different capacities.

                                He holds a B.S. in Economics from the Universidad de San Andrés in Buenos Aires, Argentina. He is fluent in Spanish, English and Portuguese.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 8 ******************************************/}
                <div className="modal_content" id={'modal_8'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/roberto-chute-4b3310/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal8} src={cierre} alt="cierre" /></div>

                                <b>Roberto Chute</b>
                                <p>Partner | The Rohatyn Group</p>
                                Mr. Chute is a Partner and oversees the Latin America Private Markets investment team. He is based in Buenos Aires.
                                Mr. Chute leads TRG private markets investments in Latin America across a wide range of sectors including pharmaceutical, information technology, logistics, agribusiness, real estate, consumer products, retail, and mining services, among others. Mr. Chute has over 18 years of private equity investing in Latin America and serves on the Board of Directors of several portfolio companies in the region. Mr. Chute experience spans across different capital structures such as buy-outs, growth capital and mezzanine investments
                                Prior to joining TRG in 2006, Mr. Chute was a Principal at Banc of America Equity Partners Latin America (BAEP). During his tenure at BAEP, Mr. Chute was responsible of investments in Mexico, Brazil and Argentina and served as Director of the Board on several portfolio companies in the retail, telecommunications, outdoor advertising, consumer finance and waste management sectors. Mr. Chute also worked in the investment banking division of Goldman Sachs and previously worked as a senior consultant at Strat Consulting, a top management consulting firm in Argentina. Mr. Chute began his career at the Techint Group in Buenos Aires.
                                Mr. Chute received his MBA from the Kellogg Graduate School of Management at Northwestern University and a degree in Industrial Engineering from the Instituto Tecnologico de Buenos Aires, Argentina. He is fluent in Spanish and English.

"
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 9 ******************************************/}
                <div className="modal_content" id={'modal_9'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/jose-sosa-del-valle-b13b38/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal9} src={cierre} alt="cierre" /></div>

                                <b>José Sosa del Valle</b>
                                <p>Partner | Lexington Partners</p>
                                Mr. Sosa del Valle is Partner at Lexington Partners where he leads the firm’s Latin American Assets and is responsible for the Santiago office. Mr. Sosa del Valle is primarily engaged in the origination and evaluation of secondary purchases of non-U.S. private equity and alternative investments. Prior to joining Lexington in 2008, Mr. Sosa del Valle was an associate in investment banking at Goldman Sachs. Mr. Sosa del Valle graduated from the Instituto Tecnológico de Buenos Aires with an MS in industrial engineering and from Columbia Business School with an MBA.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 10 ******************************************/}
                <div className="modal_content" id={'modal_10'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/diego-gonzalez-bravo-b143821/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal10} src={cierre} alt="cierre" /></div>

                                <b>Diego Gonzalez Bravo</b>
                                <p>Executive VicePresident | ARCAP</p>
                                Vicepresident of ARCAP-Argentine Association of Private Equity, Venture and Seed Capital, Senior Managing Partner at Draper Cygnus and Managing Partner at Cygnus Capital, as well as co-founder of the Cygnus Angels Club.

                                Has a broad experience in entrepreneurship, Venture Capital, and Corporate Finance. Over the last years, he has worked with Venture Capital investments, Commercial Strategies, and Mergers and Acquisitions. He was Partner at S&A – Morrison KSI, and was in charge of Corporate Finance. He is a founding member of the Argentine Entrepreneurs Association (ASEA). Before Cygnus and S&A, Diego worked in the finance and audit department at PricewaterhouseCoopers, where he specialized in M&A, valuations, due Diligence, fund raising, and financial structuring.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 11 ******************************************/}
                <div className="modal_content" id={'modal_11'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/isaantonaccio/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal11} src={cierre} alt="cierre" /></div>

                                <b>Isabella Antonaccio</b>
                                <p>IT Aftercare Specialist, en Uruguay XXI</p>

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 12 ******************************************/}
                <div className="modal_content" id={'modal_12'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/yigal-erlich-879869/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal12} src={cierre} alt="cierre" /></div>

                                <b>Yigal Erlich</b>
                                <p>FOUNDER |  YOZMA FUND</p>
                                Yigal Erlich is the founder and managing partner of Yozma which was established on 1992, as a $100M VC fund, sponsored by the government of Israel. Yozma founded ten new venture funds that were the building blocks of the vibrant venture capital industry in Israel.
                                Following its privatization in 1997, Yozma establish two more funds, managed over US $ 220 million and has invested in over 45 Portfolio companies.
                                Between 1984 and 1992, Mr. Erlich served as the Chief Scientist of Israel's Ministry of Industry and Trade, with an annual budget of $200 million, primarily directed at R&D projects of high-tech companies. In addition, Mr. Erlich started the Technology Incubator Program that led to the creation of 24 Incubation Centers throughout Israel, and initiated the establishment of several bi-national industrial and technology R&D cooperation agreements with Canada, France, the Netherlands, Singapore, Spain and more.
                                Mr. Erlich was the Deputy Chairman of the National Research & Development Council of Israel and co-Founder and first Chairman of Israel VC Association
                                Mr. Erlich was a Director of major government companies (Dead Sea Works, Israel Chemicals, Israel Oil Refineries), Hadassah's commercialization company - Hadassit, and the Technion Research and Development Co. Ltd, as well as in many boards of portfolio companies that Yozma invested in. A Board Member (five years) of the Russian Venture Capital Company (RVC), a $1 billion government fund of funds and was a consultant to several governments
                                As of today, Erlich and Yozma is a Venture Capital consulting and investments company, in Israel and abroad
                                Mr. Erlich holds B.Sc. from Bar Ilan University and M.Sc. in Chemistry and an MBA from the Hebrew University of Jerusalem.

"

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 13 ******************************************/}
                <div className="modal_content" id={'modal_13'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/antoniarojase/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal13} src={cierre} alt="cierre" /></div>

                                <b>Antonia Rojas Eing</b>
                                <p>Partner | ALLVP</p>
                                Antonia is Partner of ALLVP, Venture Capital  leading investment fund in Spanish-speaking Latin America. Before ALLVP, Antonia was a partner of the Chilean fund Venture Capital Manutara Ventures and was an investment manager in the real estate fund Trigono Inversiones. She is a Commercial Engineer from the Pontificia Universidad Católica de Chile and completed a Master in Social Entrepreneurship at Hult International Business School in San Francisco. Antonia is a member of the Kauffman Fellows Class 25, and has been recognized among the top technology investors in the region in 2018 and 2019 by LAVCA.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 14 ******************************************/}
                <div className="modal_content" id={'modal_14'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/luisbermejo/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal14} src={cierre} alt="cierre" /></div>

                                <b>Luis Bermejo</b>
                                <p>Managing Partner | Alaya Capital Partners</p>
                                "Co-founder and Managing Partner of ALAYA Capital Partners, a Venture Capital fund with presence in Argentina, Chile and the USA, focused on innovative technological projects in Latam.

                                Member of the Board of Endeavor Córdoba and teacher and mentor in various educational institutions and ONGs of the entrepreneurial ecosystem.
                                "

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 15 ******************************************/}
                <div className="modal_content" id={'modal_15'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/valentina-terranova-85496294/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal15} src={cierre} alt="cierre" /></div>

                                <b>Valentina Terranova</b>
                                <p>Parter | Draper Cygnus</p>
                                Valentina Terranova is an auspicious talent with an entrepreneurial past and experience in business development, marketing and finance. She is deeply involved in the venture capital world and founded Embarca Accelerator in Argentina in 2017. She actively participates in the Argentine Venture Capital Association and in Endeavor Argentina as a board member of Endeavor Cuyo.
                                She joined Draper Cygnus as a Partner to work in dealflow generation, portfolio development and investor relations.
                                Valentina holds a Business Administration degree from Universidad Nacional de Cuyo, an specialization in Digital Marketing from ADEN Busines
                                School and she completed a postgraduate program in Venture Investments from Universidad Austral."

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 16 ******************************************/}
                <div className="modal_content" id={'modal_16'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/isabellechaquiriand/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal16} src={cierre} alt="cierre" /></div>

                                <b>Isabelle Chaquiriand</b>
                                <p>President | Xcala </p>
                                Isabelle Chaquiriand is President of Xcala, the Latin American Angel Investment Association and an IDB consultant on business development issues. Additionally, she is vice president of ATMA, a leading company in the design, manufacture and distribution of packaging for industry and the home in Uruguay.

                                She is a doctor in Business Administration from Grenoble Ecole de Management, France; Master in Business Management and Administration from IEEM, University of Montevideo; Public Accountant from the University of the Oriental Republic of Uruguay and has studied entrepreneurship at Babson College, United States.

                                She is a senator for Uruguay at the WBAF (World Business Angel Forum). She is a member of the Board of Directors of the Uruguayan Chamber of Industries and is a member of the Astur Foundation Advisory Committee, which serves vulnerable sectors of the population. She was the founder of Corazoncitos, in support of children with congenital heart disease in Uruguay.

                                She is a columnist for various media and an international guest speaker on business development issues.

                                She was awarded in 2010 and 2011 as Manufacturing businesswoman of the Year and in 2016 she was recognized as Emerging Entrepreneur of the Year by Ernst & Young. He is currently ranked number 16 among the 100 best business leaders in Uruguay according to the Merco Ranking.

                            </div>
                        </div>
                    </div>
                </div>
                {/***************************** MODAL 17 ******************************************/}
                <div className="modal_content" id={'modal_17'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/msilvamartinez/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal17} src={cierre} alt="cierre" /></div>

                                <b>Manuel Silva</b>
                                <p>General Partner | Mouro Capital </p>
                                General Partner at Mouro Capital
                                Manuel oversees Mouro Capital’s strategy, investor relations, resources and processes as our General Partner authorised by the FCA, with his day-to-day investment activity focusing more on UK, Europe and Latin America. Manuel sits on the boards of a55, bonify and Crosslend and, as observer, of Autofi, Creditas, Curve, Elliptic, Klar, Roostify and Tradeshift among others. Manuel has been investing in fintech since 2010, having spent five years in San Francisco with BBVA Ventures (now Propel Venture Partners) before moving to London in 2015. He is a hiking enthusiast, world traveller (66+ countries) and wine aficionado.
                                Education: BA and MSc Business Administration and Audit (CUNEF), MPhil PPE, MPhil DevEcon, PhD ‘all but thesis’ Econ (Sciences Po Paris, Chinese University of Hong Kong).

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 18 ******************************************/}
                <div className="modal_content" id={'modal_18'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/agrotondo/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal18} src={cierre} alt="cierre" /></div>

                                <b>Agustín Rotondo</b>
                                <p>Regional Manager | Wayra Hispam. </p>
                                Agustin Rotondo has a degree in Industrial Engineer at the ITBA University and has a double title MBA in the ESIC Business School of Madrid and SISU University of Shanghai. In 2008, Rotondo join Telefónica being responsible of different local and global roles. After working a year and a half as Country Manager of Wayra Argentina, today he leads the position of Regional Manager for Wayra Hispam.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 19 ******************************************/}
                <div className="modal_content" id={'modal_19'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/stephen-lile-b44aa1120/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal19} src={cierre} alt="cierre" /></div>

                                <b>Stephen Lile</b>
                                <p>Co-founder & Managing Partner | Oxbridge Capital Partners </p>
                                Stephen Lile is an Oxford and Cambridge University educated scholar and academic who is also an Emmy Award Winning Media Technically Executive and an accomplished Entrepreneur. He is a recognized Thought Leader and Public Speaker in venture capital and entrepreneurship with more than 25 years of success that spans media, technology, IoT, investment banking, and wealth management. He has launched several disruptive companies with global scale, resulting in successful exits for founders and investors. Stephen is also a Hollywood producer with over 100 hours of Prime-Time network programming as an Executive Producer for PBS, and as a producer of independent film. His media accolades include winner of Worldwide Premier 2006 AFI Film Festival and the Oregon Governor’s Award for being a Media Technology Pioneer and Innovator. More recently, Stephen co-founded Oxbridge Angels and Oxbridge Capital Partners out of Cambridge University’s Judge Business School, which features early-stage science and deep technology SMEs out of Oxford and Cambridge Universities. This last year, Oxbridge CP formed a strategic partnership with Chile’s Genesis Ventures, which was recently approved for a multimillion-dollar investment through CORFO.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 20 ******************************************/}
                <div className="modal_content" id={'modal_20'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/sergiofogel/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal20} src={cierre} alt="cierre" /></div>

                                <b>Sergio Fogel</b>
                                <p>Co-Founder en DLocal</p>

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 21 ******************************************/}
                <div className="modal_content" id={'modal_21'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/hernan-kazah-95810/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal21} src={cierre} alt="cierre" /></div>

                                <b>Hernán Kazah</b>
                                <p>Co-Founder & Managing Partner | Kaszek Ventures</p>
                                Hernan Kazah is a Co-founder and Managing Partner at Kaszek Ventures, the largest Venture Capital technology industry firm in Latin America.
                                Before founding Kaszek Ventures in 2011, he co-founded MercadoLibre in 1999 (NASDAQ: MELI) the largest platform of electronic commerce and online payments in Latin America. He worked there for twelve years, the first 9 as COO and the last three as CFO.
                                Prior to this, he worked as Brand Manager at Procter & Gamble Co., and was a financial analyst at the United Nations Development Program and at a stockbroker firm.
                                Additionally, Mr. Kazah is currently a Board Member at several technology companies  in Latin America.
                                He received an MBA from Stanford University and graduated magna cum laude in Economics from the University of Buenos Aires.
                                Mr. Kazah was also selected Endeavor Entrepreneur in 1999, awarded “The Outstanding Young Persons of Argentina” recognition by the Junior Chamber International in 2004, chosen Established Endeavor Entrepreneur in 2009, and obtained the Entrepreneur Master of the Year 2018 award by Ernst & Young Argentina together with his Partner Nicolás Szekasy.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 22 ******************************************/}
                <div className="modal_content" id={'modal_22'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/marialauratinelli/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal22} src={cierre} alt="cierre" /></div>

                                <b>María Laura Tinelli</b>
                                <p>Director | Acrux Partners</p>
                                María Laura is Director of Acrux Partners, a firm specialized in impact investing based in Argentina and the United Kingdom. He has vast experience in structuring and advising on vehicles and sustainable and impact investment opportunities. Among others, the development with IDB MIF of the guidelines for the first impact investment fund for the Southern Cone, the structuring and launch of the first Social Impact Bond in Argentina, and the advice for the first Sustainable Bond aligned to ICMA Standards and ODS of the Banco de Desarrollo Productivo de Bolivia.

                                She is a Member of the Board of Directors of the Global Steering Group on Impact Investment, a Member of the Advisory Committee of the Latin American Venture Philanthropy Network, and a representative of SDG Impact Standards for the United Nations Development Program for Latin America. She leads the Working Group on Impact Investing for Argentina and is co-founder of the Latin American Network of Payment for Results and LatinSIF.


                            </div>
                        </div>
                    </div>
                </div>


                {/***************************** MODAL 23 ******************************************/}
                <div className="modal_content" id={'modal_23'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/eugeniop/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal23} src={cierre} alt="cierre" /></div>

                                <b>Eugenio Pace</b>
                                <p>CEO & CoFounder at Auth0</p>
                                Eugenio Pace co-founded Auth0 in early 2013 with CTO and “brother-in-arms” Matias Woloski while living 7,000 miles apart from each other. Since then, Pace has played an instrumental role in growing Auth0 into a leading identity management company that is loved by developers and trusted by global enterprises.

                                With more than 9,000 customers in 70 countries, and securing 4.5 billion login transactions per month, Auth0 is a trusted global brand to facilitate identity and protect customer and user data.

                                Eugenio Pace spent his entire professional career facilitating the work of developers, creating industry standard tools and content, and services for cloud computing, mobility and identity. Prior to Auth0, he spent more than a decade at Microsoft, where he led Product Management and Engineering teams. He also co-wrote several books on cloud computing and identity management.

                                Eugenio is an Engineer. He studied at the Instituto Tecnológico de Buenos Aires (ITBA).


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 24 ******************************************/}
                <div className="modal_content" id={'modal_24'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ricardodonosoinsunza/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal24} src={cierre} alt="cierre" /></div>

                                <b>Ricardo Donoso</b>
                                <p>CoFounder & CEO Americas | Frontier Car Group / OLX Autos</p>
                                Ricardo grew up in Sweden and moved back to his birth country Chile when he was 16 years old.He was a junior highly ranked tennis player and retired early because of injuries when he was 17 years old. At age 27 he started his business career when founding his first company Celmedia in 2002. Celmedia was the first mobile value-added services company in Latin America which he expanded into 11 countries andexited in 2010. In 2011, he co founded emerging market mobile phone brand Azumi, which he exited in 2015 and led to become one of the top selling mobile phone brands in several markets by selling 5 million handsets in +20 countries. In 2016,he co-founded Frontier Car Group, a used car automotive marketplace that simplifies the used car buying and selling experience by using technology and infrastructure development. Frontier Car Group was recently valued as a Unicorn after Naspers did a USD 482M investment via its subsidiary Olx Group and then merged FCG with Olx emerging markets unit. Ricardo sits on several boards of technology-based companies and has conducted multiple turnarounds successfully. He is also a founding partner of Manutara Ventures, a Latam based venture capital firm.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 25 ******************************************/}
                <div className="modal_content" id={'modal_25'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/marianoamartino/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal25} src={cierre} alt="cierre" /></div>

                                <b>Mariano Amartino</b>
                                <p>Managing Director | Microsoft for startups LATAM</p>
                                Mariano Amartino is Microsoft Startups Director for Latinamerica, where he runs the startups programs and engagements with the innovation ecosystem.

                                Prior to this he was Managing Director of Wayra, Telefónica´s OpenFuture start-up Accelerator running operation on 12 countries in the region where Wayra has a portfolio of more than 600 innovative companies in 20 different digital industries.

                                From 2009 to 2011, when he joined Wayra, Mariano was the CEO of Hipertextual taking it to be leading blogging network in spanish language.  He currently serves as board member and advisor for several startups and still blogs at uberbin.net one of the first spanish blogs where he covers Online Communities, Internet and New Media topics


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 26 ******************************************/}
                <div className="modal_content" id={'modal_26'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/julio-dittborn-b4b7932b/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal26} src={cierre} alt="cierre" /></div>

                                <b>Julio Dittborn Chadwick</b>
                                <p>Principal Private Equity | Megeve Investments</p>
                                Julio Dittborn is Principal of Private Equity at Megeve Investments, the Family Office of the Solari Donaggio Family. Prior to joining the Private Equity team, from 2006 to 2010, he worked in the trading desk, first as an analyst and the as the portfolio manager of the Latin American equities Portfolio. He has 14 years of experience investing in multiple sectors and countries in Latin America, including among others Peru, Colombia, and Paraguay.
                                He has led several companies under Megeve control in different industries as energy generation, real estate, forestry, among others. Mainly his involvement has been in strategic and financing matters. He has served also as a board member in Colgener, a 330MW power plant in Colombia and Parque del Recuerdo, a cemetery company in Chile.
                                Mr. Dittborn has an MBA from The Wharton School at the University of Pennsylvania (WG12). He is a Commercial Engineer from Universidad Católica de Chile.


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 27******************************************/}
                <div className="modal_content" id={'modal_27'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/colincurvey/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal27} src={cierre} alt="cierre" /></div>

                                <b>Colin Curvey</b>
                                <p>Fund Head at IFC Asset Management Company</p>
                                Colin has twenty years of experience in international private equity spanning middle market buyouts in Western Europe to growth equity investing in emerging markets.  Over the course of his career he has led investments across a variety of sectors including financial services, consumer, energy and infrastructure and served on the boards of portfolio companies in Europe, Latin America and Africa.
                                Colin currently heads a $1 billion fund focused on Latin American & Africa at the IFC Asset Management Company (“AMC”).  AMC is the captive GP of the International Finance Corporation, which manages third party institutional capital on behalf of some of the world’s largest institutional investors.  He was an early joiner of AMC and part of the team that built it from a start-up within IFC to US$10 billion raised across thirteen funds.
                                Previously Colin was a Partner of Duke Street, the London-based private equity firm where he focused primarily on control-oriented buyouts in the financial services and consumer sectors.   Prior to finishing his MBA, Colin was with the investment bank Morgan Stanley in New York and Celfin in Santiago, Chile.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 28******************************************/}
                <div className="modal_content" id={'modal_28'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/macarena-navarrete-91b2a06/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal28} src={cierre} alt="cierre" /></div>

                                <b>Macarena Navarrete</b>
                                <p>Country Managing Partner | EY Chile</p>
                                On January 1, 2018, Macarena assumed her role as Country Managing Partner.
                                Macarena is part of the Board of Directors of the Chilean North American Chamber of Commerce, of Inbest, of the Finance Circle in Icare and, of the Chilean Transparent Advisory Council.  She has been chosen five times as part of the “100 Women Leaders in Chile”.

                                Macarena is a lawyer, she studied at the University of Chile, and before coming to EY she worked in recognized law firms, in the areas of criminal litigation.

                                Her leadership has been strongly inclusive, spearheading initiatives regarding gender and sexual diversity, generations and immigration, and people with disabilities. In addition, Macarena is fostering an environment of innovation and digital development within the firm.


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 29 ******************************************/}
                <div className="modal_content" id={'modal_29'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/jorge-arg%C3%BCello/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal29} src={cierre} alt="cierre" /></div>

                                <b>Jorge Argüello</b>
                                <p>Argentine Ambassador to the United States</p>
                                On February 6, 2020, Jorge Argüello assumed the position of Ambassador of Argentina to the United States.

                                Ambassador Jorge Argüello is a lawyer and politician.

                                Mr. Jorge Argüello began his political career in 1987 as a member, then Chairman, of the City Council of Buenos Aires. He served twice as a National Congressman; the first time during Carlos Menem´s presidency in 1991 and then during Nestor Kirchner´s presidency in 2003.

                                Mr. Argüello has a wide-ranging experience in the public sector; as a member of Congress he was appointed Chairman of the Foreign Affairs Committee and Chairman of the Parliamentary Observatory for the Malvinas Question. In 2018 he became Secretary of State, in the Official Representation for the Malvinas Question in Tierra del Fuego province, Antarctica and South Atlantic Islands.

                                Mr. Argüello has worked extensively in the diplomatic field; as Permanent Representative of Argentina to the U.N. (2007-2011), Ambassador of Argentina to the United States of America (2011-2012), and Ambassador of Argentina to Portugal and Cabo Verde (2013-2015).

                                He later set up his own Foundation, Embajada Abierta, a center for international affairs and public policy. He is a member of the Advisory Board of the Argentine Council for International Relations (CARI).

                                Ambassador Argüello was a member of the Advisory Board of the Center for Latin American Studies (CLAS) at the School of Foreign Services, Georgetown University, and a Research Associate of the Centro de Estudos Internacionais (CEI) at the Portugal ISCTE-IUL University. He also served as Director of the Government and International Relations  studies program at the Universidad Argentina de la Empresa (UADE).

                                He was appointed Ambassador to the United States of America by President Alberto Fernandez in early 2020.

                                He is married to Erika Grinberg and has four children.


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 30 ******************************************/}
                <div className="modal_content" id={'modal_30'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ivonnecuello/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal30} src={cierre} alt="cierre" /></div>

                                <b>Ivonne Cuello</b>
                                <p>CEO | LAVCA</p>
                                Ivonne Cuello is the CEO at the Association for Private Capital Investment in Latin America (LAVCA), a non-profit membership organization comprised of over 190 firms that control assets in excess of US$65b directed at capitalizing and growing Latin American businesses. Members include the most active private equity, venture capital, real estate, and infrastructure funds, as well as family offices, sovereign wealth funds, pension funds, and other private capital investors.

                                Prior to joining LAVCA, Ivonne was Director of Entrepreneurship at ANDI (Asociacion Nacional de Empresarios de Colombia), a private sector organization in Colombia. In this role she headed and launched their Entrepreneurship Program and built the National Mentor and Investment Angel platform, with the support of MIT and C-Level Executives and HNWI. Ivonne also previously worked at Proexport Colombia, a government investment agency where she liaised with private equity investors from the US, Canada, the UK, and Asia. In addition, she collaborated with the Ministry of Finance and a local development bank in an effort to enhance the regulatory framework for private equity. Ivonne has her Masters of Science in Global Management from the Université Paris-Dauphine and a Bachelor of Arts in Economics from the Universidad de los Andes.


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 31 ******************************************/}
                <div className="modal_content" id={'modal_31'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/sarah-bennani-464990b/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal31} src={cierre} alt="cierre" /></div>

                                <b>Sarah Bennani</b>
                                <p>Investment Officer | IFC Private Equity Funds</p>
                                Sarah Bennani is an Investment officer based within Washington DC with the IFC, where she is covering PE and VC funds for Latin America and the Caribbean.

                                She began her career with IFC in the Istanbul office for 4 years, where she covered new and existing funds business for IFC’s PE funds practice. Prior to joining IFC in 2012, Sarah worked in asset management, working at Massena Partners, a multifamily office based in Paris and AGF PE (now Idinvest Partners), a European private equity and venture capital firm. Sarah has overseen fund investments in MENA, Africa, Russia, Europe, Asia and across Latin America.

                                Sarah has a Master’s degree from ESCP Europe Business School in France.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 32 ******************************************/}
                <div className="modal_content" id={'modal_32'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/mangarelli/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal32} src={cierre} alt="cierre" /></div>

                                <b>Eduardo Mangarelli</b>
                                <p>President | Endeavor Uruguay Partner | IC Ventures</p>
                                Eduardo Mangarelli is a Systems Engineer, Dean of the Faculty of Engineering of the ORT Uruguay University, President of Endeavor Uruguay and a member of the Board of the Uruguayan Technological Laboratory. He previously served as Microsoft's Principal Software Engineer Lead globally. He is also a partner and director of Tryolabs, Intermedia, MonkeyLearn and the investment fund IC Ventures.


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 33 ******************************************/}
                <div className="modal_content" id={'modal_33'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ericmanlunas/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal33} src={cierre} alt="cierre" /></div>
                                <b>Eric Manlunas</b>
                                <p>Founder and Managing Partner at Wavemaker Partners</p>
                                Eric Manlunas is the Founder and Managing Partner of Wavemaker Partners, an early-stage cross border venture capital firm he founded in 2003 that’s dual headquartered in Los Angeles and Singapore with over $400M of assets under management.  Eric is a two-time start-up entrepreneur turned venture capitalist who has an extensive track record as an early investor in over 350 early-stage businesses.  Prior to becoming a venture capitalist, Eric founded two technology start-ups, one in e-Commerce in 1996 and the other in Internet services in 1999, both of which were successfully built and eventually sold to strategic buyers.

                                Eric began his career as a consulting associate with Arthur Andersen’s retail management consulting division from 1991-1995. He earned an MBA from Pepperdine University in 1995 and an undergraduate degree in Communications from Florida International University in 1990.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 34 ******************************************/}
                <div className="modal_content" id={'modal_34'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/maria-de-los-angeles-romo-a1b7a323/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal34} src={cierre} alt="cierre" /></div>

                                <b>María de los Ángeles Romo</b>
                                <p>Manager Startup Chile</p>
                                Over 20 years of experience in strategic consulting, business financing, incubation, business acceleration, business angels, and venture capital.

                                She was Executive Director of Endeavor in Chile, Director of Women Entrepreneurs, Broota, Inria Chile, among others. She served as Corfo's Corporate Manager and as Strategy Director. Additionally, she is the Director of the public company Econssa S.A. and Lapsa S.A., member of the strategic committee of the companies DCanje and E; Brown.

                                Professor of the Technological Entrepreneurship course of the MBA of the PUC and of the Master in Innovation of the same University. Forest Engineer from the Universidad Católica de Chile, Master in Finance from Universidad Adolfo Ibáñez, Certificate in Investment Fund Management (Venture Capital) from Universidad Adolfo Ibáñez and specializations in leadership and entrepreneurship Stanford University, San Francisco; Harvard Business School, Boston and Columbia Business School NY. Four times elected Women Leaders by El Mercurio.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 35 ******************************************/}
                <div className="modal_content" id={'modal_35'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/patriciapastorg/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal35} src={cierre} alt="cierre" /></div>

                                <b>Patricia Pastor</b>
                                <p>Managing Director at GoHub by Global Omnium & General Partner at GoHub Ventures.</p>
                                Managing Director of GoHub by Global Omnium and General Partner of GoHub Ventures. With extensive experience in the area of entrepreneurship, for several years she was responsible for the Valencia City Council for the development of the ecosystem and created the first public startup community in Spain with 5,000 members. In all this time, she has become a highly valued figure in the industry for helping to create a cohesive community. She was previously an entrepreneur and worked in several accelerators.

                            </div>
                        </div>
                    </div>
                </div>
                {/***************************** MODAL 36 ******************************************/}
                <div className="modal_content" id={'modal_36'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/carmen-correa-78594724/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal36} src={cierre} alt="cierre" /></div>

                                <b>Carmen Correa</b>
                                <p>COO at Pro Mujer | Board Member at Pymecapita</p>
                                Carmen Correa is Chief Operations Officer at Pro Mujer.  Pro Mujer is one of Latin America’s leading women’s development organizations with an integrated approach that offers access to finance, health and educational services.  During 2015 and 2016 Carmen was hired by the National Agency for Research and Innovation (ANII) in Uruguay to develop a new instrument that intended to finance a venture capital fund and business accelerator.  From January 2010 until May 2015, Carmen was responsible for Avina Uruguay´s strategy and the Impact Businesses´ strategy at the regional level. Avina’s main objective is to contribute to concrete and relevant changes that lead to more sustainable development in Latin Americais. From 2005 to 2009 Carmen was the Managing Director of Endeavor Uruguay and from 2000 to 2004 she was the Chief Operations Officer of the same organization.   Endeavor is an international non for profit organization that supports high-impact entrepreneurs.  From 1999 to 2000 she was the Administrative Manager at DeRemate.com in Uruguay an online auction site.   From 1992 to 1999 Carmen occupied various positions at the Inter-American Development Bank (IDB) in Washington DC.  From 1990 to 1992 she worked at the Organization of the American States (OAS) in Uruguay.    Carmen holds a BS in Management from National- Louis University in McLean, VA, she has a Business Administration Certificate from Georgetown University, among other management development courses from John Hopkins University in Washington DC, USDA in Washington DC and ESADE in Uruguay.  Carmen is married and has three kids.
                                Carmen is board member of Pymecapital an impact investment fund based in Nicaragua and of Educacion Responsable an NGO that promotes emotional intelligence in Uruguay.  Carmen is member of the advisory board of Socialab, organizations that promotes social entrepreneurship and supports other local civil society organizations.  She is a Mentor of Endeavor Uruguay and OMEU (Women Enterprise Organization of Uruguay). Likewise, Carmen co-leads the Southern Cone Task Force of the Global Steering Group on Impact Investment.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 37 ******************************************/}
                <div className="modal_content" id={'modal_37'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/carmen-correa-78594724/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal37} src={cierre} alt="cierre" /></div>

                                <b>Saul Singer</b>
                                <p>Co-author  “Start-Up Nation: The Story of Israel's Economic Miracle”</p>
                                Saul Singer is the co-author, with Dan Senor, of the best-selling book Start-Up Nation: The Story of Israel's Economic Miracle. The book was highly acclaimed, reaching the best-seller list of The New York Times and the Wall Street Journal and has since been translated to over 20 languages.

                                Singer served as an adviser in the United States Congress to the House Foreign Affairs and Senate Banking Committees before becoming a columnist and writer for several major publications.

                                Singer regularly wanders the world (now via Zoom...) giving keynotes at innovation related conferences, where he speaks about how Israel and other countries can transform major parts of our lives, such as health, education, and cities. He serves on various boards, including Innovation and Science Australia, a government oversight body; Vintage Investment Partners, Israel's only fund-of-funds; and Tevel B'tsedek, an Israeli NGO working in Nepal and other countries.

                                Singer was born in New York and now lives in Jerusalem with his wife Wendy and three daughters.



                            </div>
                        </div>
                    </div>
                </div>
                {/***************************** MODAL 38 ******************************************/}
                <div className="modal_content" id={'modal_38'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/alfonsoamat/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal38} src={cierre} alt="cierre" /></div>

                                <b>Alfonso Amat</b>
                                <p>Head of Startup Business Development - LatAm en Amazon Web Services (AWS)</p>
                                Successful entrepreneur who founded, developed and finally sold his Startup to Globant (NYSE: GLOB). Intrapreneur in large corporations such as Accenture and Telefónica / Movistar managing Innovation and digital transformation.

                                He worked in Germany, Argentina, Brazil, Chile, Spain, Holland, England, Switzerland and Uruguay.


                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 39 ******************************************/}
                <div className="modal_content" id={'modal_39'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="hhttps://www.linkedin.com/in/alejandro-mashad-329174/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal39} src={cierre} alt="cierre" /></div>

                                <b>Alejandro Mashad</b>
                                <p>Director at Centro de Entrepreneurship de la Universidad de San Andrés</p>
                                He is Director of the Center for Entrepreneurship at the Universidad de San Andrés and professor of the subject. Until 2011 he was Executive Director of the Fundación Endeavor Argentina, an international organization that supports the development of entrepreneurs and their ecosystem.

                                Alejandro is from Mendoza, a Civil Engineer received with honors from theUniversidad Nacional de Cuyo. With a scholarship from the Reuters Foundation, he studied an MBA at IESE (University of Navarra, Barcelona) and at the Kellogg School of Management (Northwestern University). He served for almost 4 years at The Boston Consulting Group as a consultant. Until Oct 2017 he was Director of the Entrepreneurs Center of UMET (Universidad Metropolitana para la Educación y el Trabajo), the first university in the country to establish entrepreneurship as transversal and mandatory for all careers.

                                He sporadically carries out activities as a visiting professor in subjects related to the entrepreneurial theme and as a speaker at events related to the subject. He is also participating in some board of directors of startups.



                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 40 ******************************************/}
                <div className="modal_content" id={'modal_40'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/meghansk/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal40} src={cierre} alt="cierre" /></div>

                                <b>Meghan Stevenson-Krausz</b>
                                <p>Director | INCA Ventures</p>
                                Meghan Stevenson-Krausz is a Director at INCA Ventures, a fintech-focused VC fund based in Lima, Peru. Before joining INCA and moving to Peru, she spent the last 5 years working in both nonprofit and VC in the San Francisco-Bay Area. She has a degree in International Relations from the University of St Andrews and a MSc in Human Rights from the London School of Economics. Meghan is excited to bring her expertise to directly support founders in Latin America, and seeks to create a more diverse and inclusive VC sector in Latam. She is also a Founding Board Member of WeInvest, the first community for women investing across all of Latin America.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 41 ******************************************/}
                <div className="modal_content" id={'modal_41'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ericmanlunas/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal41} src={cierre} alt="cierre" /></div>




                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 42 ******************************************/}
                <div className="modal_content" id={'modal_42'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/francisco-guzm%C3%A1n-64277513/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal42} src={cierre} alt="cierre" /></div>

                                <b>Francisco Guzmán</b>
                                <p>Vice presidente ACVC</p>

                                Partner of Carey, the largest law firm in Chile, and Director of the Chilean Venture Capital Association. At Carey he is one of the lawyers in charge of the Funds, Venture Capital and Private Equity practice.
                                Francisco is a lawyer from the Pontificia Universidad Católica de Chile and LL.M from Columbia University (James Kent Scholar, highest distinction awarded by the School of Law) (2010). He is admitted to practice as a lawyer in Chile and in New York.
                                Author of the book "Privileged information in the stock market", LexisNexis (2007), 2nd edition (2009). He is director of the SOFOFA HUB (Technological Hub of the Federation of Industries) and of the Business Accelerator of Mujeres Empresarias. Francisco has been recognized as a leading Venture Capital lawyer by The Legal 500 (leading individual), Leaders League, and by Best Lawyers. He was also recognized as the Best Individual Lawyer in Private Funds in Chile by Client Choice (2020).



                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 43 ******************************************/}
                <div className="modal_content" id={'modal_43'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/andres-ackermann-cfa-7232116/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal43} src={cierre} alt="cierre" /></div>

                                <b>Andrés Ackermann</b>
                                <p>Principal Investment Officer - Head of Investment Funds | IDB Invest</p>
                                Andrés is the Head of Investment Funds at IDB Invest, which he joined in 2016. He focuses
                                on financial markets. Currently, he is responsible for investments made by the organization
                                through investment funds, including private equity, private credit and venture capital in Latin
                                America and the Caribbean. Andrés is a member of the Limited Partner Advisory Committees of various investment funds in which IDB Invest holds equity.

                                Before joining the IDB Group, he worked as Corporate Banking Manager and Corporate
                                Finance/Capital Markets Manager at Citibank in Bolivia and as Middle-Market Banking Manager and Financial Planning Manager at Banco de Crédito, also in Bolivia.

                                Andrés earned a master’s degree in finance from Boston College and a master's degree in
                                economics from Florida International University (USA). He is a CFA Charterholder.




                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 44 ******************************************/}
                <div className="modal_content" id={'modal_44'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ignaciohecquet/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal44} src={cierre} alt="cierre" /></div>

                                <b>Ignacio Hecquet</b>
                                <p>Head of Strategy and Transactions for Argentina, Uruguay and Paraguay  | EY </p>
                                Ignacio Hecquet is the head of the Strategy and Transactions practice through which EY advises clients in matters relating to Strategy, M&A, Due Diligence, Corporate Finance, Valuation, Business Modeling and Operational Transactional Services.

                                In the last years, Ignacio has led numerous consulting projects in transactions in Latin America for multinational corporations and Private Equity and Venture Capital Firms.

                                Previously, Ignacio was an Assurance partner, in charge of the Technology, Telecommunications and Entertainment sector. In this role, he led several initial public offerings in Argentina and USA, external audits and risk management projects.

                                Ignacio is a Public Accountant by the Universidad de Buenos Aires. Currently, he is a professor at Universidad Di Tella and Universidad de Buenos Aires. He is also a mentor with Endeavor.

                            </div>
                        </div>
                    </div>
                </div>
                {/***************************** MODAL 45 ******************************************/}
                <div className="modal_content" id={'modal_45'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/javierartigasherrera/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal45} src={cierre} alt="cierre" /></div>

                                <b>Javier Artigas Herrera</b>
                                <p>CEO & Founder at Connectus Medical Group</p>
                                Javier is Uruguayan, president of the companies Connectus Medical Group, UMBRA Visual Intelligence and MobilCue Ltd. all based in Israel. As a patient with chronic kidney failure and later a kidney transplant, he works for the MIT Solve program at the Massachusetts Institute of Technology, seeking solutions for chronic diseases through machine learning and emotions. He wrote the book "The Book Israel - Uruguay: The Legacy of Entrepreneurs" supported by the Embassy of Israel in Uruguay and the House of Representatives of Uruguay. Today he is writing his second book. He has been the winner of awards such as: MIT Technology Review Award, Startup Nation Jerusalem Award, special mention from the Copenhagen Institute for Futures Studies in Denmark, among others. Speaker on innovation for the IDB, MIT, Instituto Karolinska, Universidad de Sodertorn, Universidad Hebrea de Jerusalem,  Universidad Católica de Córdoba Argentina, Universidad de Católica de Uruguay, Universidad Tecnológica de Honduras, Universidad de Montevideo, Universidad ORT del Uruguay. He has participated in various TEDx talks. He has been featured in lectures by Nobel Prize in Economics Alvin Roth as an agent of change in the new sharing economy. He is the author of the UMBRA program: ¨The truth behind the truth¨ Microexpressions and facial coding, applied by security, civil and military organizations in Israel and other countries. The story of his life, together with Hernán Casciari's heart attack and the arrival of Joe Gebbia -co-founder of Airbnb- (at his home in Montevideo) was acquired by Disney Globall to be converted in a movie.
                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 46 ******************************************/}
                <div className="modal_content" id={'modal_46'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/francisco-guzm%C3%A1n-64277513/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal46} src={cierre} alt="cierre" /></div>

                                <b>Francisco Guzmán</b>
                                <p>Vice presidente ACVC</p>
                                Socio de Carey, la oficina de abogados más grande de Chile, y Director de la Asociación Chilena de Venture Capital. En Carey es uno de los abogados a cargo de la práctica de Fondos, Venture Capital y Private Equity.Francisco es abogado de la Universidad Católica y LL.M de Columbia University (James Kent Scholar, máxima distinción otorgada por la Escuela de Derecho) (2010). Está admitido para ejercer como abogado en Chile y en Nueva York. Autor del libro “Información privilegiada en el mercado de valores”, LexisNexis (2007), 2ª edición (2009). Es director del SOFOFA HUB (Hub Tecnológico de la Federación de las Industrias) y de la Aceleradora de Negocios de Mujeres Empresarias. Francisco ha sido reconocido como un abogado líder en Venture Capital por The Legal 500 (leading individual), Leaders League y por Best Lawyers. También fue reconocido como Mejor Abogado Individual en Fondos Privados en Chile por Client Choice (2020).
                            </div>
                        </div>
                    </div>
                </div>


                {/***************************** MODAL 47 ******************************************/}
                <div className="modal_content" id={'modal_47'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    {/* <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/francisco-guzm%C3%A1n-64277513/"></a> */}
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal47} src={cierre} alt="cierre" /></div>

                                <b>Yoed Magen</b>
                                <p>Israeli ambassador to Uruguay </p>
                                Ambassador Yoed Magen, a career diplomat in the Israel Foreign Service, he was the director for Central America, Mexico and the Caribbeans at the Foreign Ministry.

                                Yoed Magen joined the Ministry of Foreign Affairs in August 1992. He subsequently served in missions abroad as member of the Israeli  Delegation to the 47th U.N. General Assembly (1993), Second Secretary in the Israeli Embassy in San Jose, Costa Rica (1994-1997), Counselor in the Israeli Embassy in Mexico City, Mexico (1997-2000); Deputy chief of Mission in the Embassy of Israel in New Delhi, India (2003-2007); Ambassador to Panama (2009-2011) and Ambassador to Colombia (2011-2014).

                                At the Foreign Ministry’s headquarters in Jerusalem, Ambassador Magen, has held positions at the Latin American Division, Economic Division and at the North America Division, served as Secretary of the Inter-Governmental Committee on the disappeared Jews in Argentina and as Policy Adviser to the Strategic Division in the Planning Branch of the Israel Defense Forces.

                                Yoed Magen holds a B.A. degree in Political Science and Criminology from Bar-Ilan University and a Practical Engineer degree in Computer Programming from O.R.T. Singalowsky.

                                Ambassador Magen is married to Adi and they have three children.


                            </div>
                        </div>
                    </div>
                </div>



                {/***************************** MODAL 49 ******************************************/}
                <div className="modal_content" id={'modal_49'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/pjamini/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal49} src={cierre} alt="cierre" /></div>

                                <b>Paimun Amini</b>
                                <p>Director of Venture Investments | Leaps by Bayer</p>
                                As the Director of Venture Investments for Leaps by Bayer, PJ drives the investment strategy that accelerates breakthrough innovations in Food & Agriculture. Coming from a scientific background and the frontlines of digital innovation, PJ and his
                                colleagues have filtered through hundreds of companies with the potential to move agriculture from the focus of simply producing MORE to producing BETTER. PJ has led investments across South America, Africa, and Asia; most recently joining the boards of Unfold Bio and Apollo Agriculture. He joined Bayer in 2011 and has held various leadership roles across the R&D and IT organizations; prior to which he was conducting research for the USDA. PJ has both a Bachelors degree in Biology and an MBA from Washington University in St. Louis.

                            </div>
                        </div>
                    </div>
                </div>


                {/***************************** MODAL 50 ******************************************/}
                <div className="modal_content" id={'modal_50'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/matiaspeire/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal50} src={cierre} alt="cierre" /></div>

                                <b>Matías Peire</b>
                                <p>Founder & CEO | GRIDX</p>
                                More than 18 years of experience in the creation and management of high-tech companies.

                                In 2013, after selling its stocks in 3Way Solutions, it began a process of research to develop a model that links science to the production world. After three years, he founded GRIDX, a company builder that develops new science-based companies, (specifically in biotechnology) assembling teams with scientists and business entrepreneurs and investing in them for their launch to the market. Since its formal inception in 2017, GRIDX created and invested in 22 biotechnology startups in Argentina and Uruguay and currently manages USD 17MM to create a portfolio of 30 companies. He has a degree in Business Administration from Universidad de Buenos Aires and a Master in Finance from Universidad de San Andrés.

                                In 2015 he participated in the executive program of Singularity University. He is part of the board of Educar2050.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 51 ******************************************/}
                <div className="modal_content" id={'modal_51'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/rodrigo-villar-9b9969/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal51} src={cierre} alt="cierre" /></div>


                                <b>Rodrigo Villar</b>
                                <p>Founder Partner at New Ventures & Adobe Capital</p>
                                Rodrigo Villar is setting a new standard in buisness. His commitment to disrupt business as usual is changing the status quo. As a social entrepreneur himself and Founding Partner at New Ventures, he has succeeded in consolidating a team of driven individuals that continue to innovate in the Impact Investing world.

                                As a pioneer in the impact entrepreneurship ecosystem, since 2004, Rodrigo and New Ventures have strived and succeeded in empowering the disrupters to challenge the status quo to design the society we strive to live in. Driven by this mission and collaborating with
                                the public and private sector, New Ventures executes various acceleration programs, operates two investment vehicles, Adobe Capital and VIWALA, and annually organizes the largest impact investing conference in the región: FLII.

                                In addition to New Ventures, he is also a recognized Ashoka Fellow and leading voice in Latin America’s impact sector. Rodrigo is currently Chair of the GSG National Advisory Board in Mexico and sits on the board of various portfolio companies of Adobe Capital,
                                CI Banco and Iniciativa Mexico.

                                Rodrigo has a Master of Business Administration from the Royal Melbourne Institute of Technology and a Bachelor of Accounting and Financial Management degree from the ITESM.

                            </div>
                        </div>
                    </div>
                </div>

                {/***************************** MODAL 52 ******************************************/}
                <div className="modal_content" id={'modal_52'}>
                    <div className="modal">

                        <div className="modal_contenedor">
                            <div className="imagen">
                                <div className="imagen_div"></div>
                                <hr />
                                <div className="redes">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/quingarcia/"></a>
                                </div>
                            </div>
                            <div className="contenido">
                                <div><img onClick={this.show_modal52} src={cierre} alt="cierre" /></div>


                                <b>Quin García</b>
                                <p>Managing Director at Autotech Ventures, Mobility & Transportation Venture Capital Investor</p>
                                Quin brings to Autotech a passion for enabling the success of mobility entrepreneurs by advising them on business development, recruitment, fundraising, and competitive differentiation. Raised in Silicon Valley, Quin is a lifelong “car guy” who has spent his career solving transportation problems with technology. He has dedicated his career to using technology to create safe, equitable, convenient, clean transportation for all.
                                In addition to his work with Autotech Ventures portfolio companies, Quin has served in his personal capacity as a Board Director, Board Observer, or Advisory Board member at numerous mobility startups, including Lyft, Peloton Technology, and Connected Signals. He was part of the founding team at the electric vehicle infrastructure startup Better Place, responsible for partnerships with automakers and parts suppliers while living in Israel, Japan, and China.
                                Quin holds a MS degree in Management Science and Automotive Engineering from Stanford and a BS degree with High Honors in Applied Economics and Management from Cornell.
                                Quin speaks English, Spanish, and Chinese and enjoys driving race cars.


                            </div>
                        </div>
                    </div>
                </div>
            </section >
        )
    }
}

export default Modals;