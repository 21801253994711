import React from 'react';
import './Agenda.css';
import Dia1 from './Dia1'
import Dia2 from './Dia2'

class Agenda extends React.Component {
    render() {


        return (
            // Componente de Agenda
            <section id={"seccion-agenda"} className="agenda">
                <div className="agenda_max">
                    <hr/>
                    <div className="titulo_agenda">
                        <div><h2>Agenda</h2></div>
                       
                    </div>


                    <div className="contenedor_agenda">
                        <Dia1 />
                        <Dia2 />
                  

                  
                    </div>

                </div>

            </section>

        );
    }
}

export default Agenda;
