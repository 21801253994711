import React from 'react';
// import './Acordeon.css';

// Este acordeon está siendo utilizado por el componente de "Agenda"
// Aqui es solo su funcionamiento, el contenido está en el componente correspondiente y mencionado anteriormente. 

class Acordeon extends React.Component {
    constructor() {
        super();
        this._handleClick = this._handleClick.bind(this);
    }

    componentDidMount() {
        this._handleClick();
    }

    _handleClick() {
        const acc = this._acc.children;

        for (let i = 0; i < acc.length; i++) {
            let a = acc[i];
            a.onclick = () => a.classList.toggle("active");
        }


    }

    render() {
        return (
            <div className="acordion"
                ref={a => this._acc = a}
                onClick={this._handleClick}>

                {this.props.children}
            </div>
        )
    }
}

export default Acordeon;