import React from 'react';

// IMPORTADOS DE LA CARPETA INGLES
import Menu from '../components/ingles/menu/Menu'
import Agenda from '../components/ingles/agenda/Agenda'
import Banner from '../components/ingles/banner/Banner'
import Acerca from '../components/ingles/acerca/Acerca'
import Contacto from '../components/ingles/contacto/Contacto'
import Organizadores from '../components/ingles/organizadores/Organizadores'
import Sponsors from '../components/ingles/sponsors/Sponsors'
import Speakers from '../components/ingles/speakers/Speakers'


// Esta es la sección de inglés. Cada sección está en su carpeta 
// correspondiente de ingles/...

// Todo el css está en cada carpeta de spanish correspondiente a cada sección
// En la sección de inglés NO hay css individuales, utiliza los mismos del spanish
// Esto con el objetivo de no duplicar css sino solo para contenidos.

class Ingles extends React.Component {
    render() {
        return (
            <section className="ingles">
                <section className="fondo_puntos"></section>

                <div>
                    < Menu />
                    <Banner />
                    <Acerca />
                    <Agenda />
                    <Speakers />
                    <Organizadores />
                    <Sponsors />
                    <Contacto />
                </div>
            </section>
        )
    }
}

export default Ingles;