import React from 'react';
import './Contacto.css';


// IMAGENES
import twitter from "../../../assets/img/contacto/twitter-icono-1.png";
import twitter_azul from "../../../assets/img/contacto/twitter-icono-2.png";
import twitter_gris from "../../../assets/img/contacto/twitter-icono-3.png";
import linkedin from "../../../assets/img/contacto/linkedin-icono-1.png";
import linkedin_azul from "../../../assets/img/contacto/linkedin-icono-2.png";
import linkedin_gris from "../../../assets/img/contacto/linkedin-icono-3.png";
import email_icono from "../../../assets/img/contacto/email-icono-1.png";
import email_icono_azul from "../../../assets/img/contacto/email-icono-2.png";
import email_icono_gris from "../../../assets/img/contacto/email-icono-3.png";

class Contacto extends React.Component {
    render() {
        return (
            <section className="contacto">
                <div className="contacto_max">
                    <hr />
                    <div className="contacto_contenedor">
                        <div className="contacto_fondo">
                            <p>Contáctenos</p>
                           
                        </div>
                        <div className="redes_contenedor">
                            <div className="redes">
                                {/* <p>Twitter</p> */}
                                <div className="red">
                                   <a href="https://twitter.com/Arcap_" target="_blank" rel="noopener noreferrer"> <img src={twitter} alt="red" /></a>
                                    <p className="white">/ARCAP</p>
                                </div>
                                <div className="red">
                                    <a href="https://twitter.com/ACVCchile" target="_blank" rel="noopener noreferrer"><img src={twitter_azul} alt="red" /></a>
                                    <p className="yellow">/ACVC</p>
                                </div>
                                <div className="red">
                                    <a href="https://twitter.com/UruguayXXI" target="_blank" rel="noopener noreferrer"><img src={twitter_gris} alt="red" /></a>
                                    <p className="">/Uruguay XXI</p>
                                </div>
                            </div>
                            <div className="redes">
                                {/* <p>LindkedIn</p> */}

                                <div className="red">
                                    <a href="https://www.linkedin.com/company/arcap-asociaci%C3%B3n-argentina-de-capital-privado-emprendedor-y-semilla-" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="red" /></a>
                                    <p className="white">/ARCAP</p>
                                </div>
                                <div className="red">
                                    <a href="https://www.linkedin.com/company/asociacionchilenadeventurecapital" target="_blank" rel="noopener noreferrer"><img src={linkedin_azul} alt="red" /></a>
                                    <p className="yellow">/ACVC</p>
                                </div>
                                <div className="red">
                                   <a href="https://www.linkedin.com/company/uruguay-xxi" target="_blank" rel="noopener noreferrer"> <img src={linkedin_gris} alt="red" /></a>
                                    <p className="">/Uruguay XXI</p>
                                </div>
                            </div>

                            <div className="redes">
                                {/* <p>Correo eléctronico</p> */}

                                <div className="red">
                                    <a href="mailto:info@arcap.org"  ><img src={email_icono} alt="red" /></a>
                                    <p className="white">/ARCAP: info@arcap.org</p>
                                </div>
                                <div className="red">
                                    <a href="mailto:contacto@acvc.cl"><img src={email_icono_azul} alt="red" /></a>
                                    <p className="yellow">/ACVC: contacto@acvc.cl</p>
                                </div>
                                <div className="red">
                                    <a href="mailto:invest@uruguayxxi.gub.uy"><img src={email_icono_gris} alt="red" /></a>
                                    <p className="">/Uruguay XXI: invest@uruguayxxi.gub.uy</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Contacto;