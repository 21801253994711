import React from 'react';
import Modals from './Modals'

class Speakers extends React.Component {

    show_modal1 = () => {
        const modal_1 = document.querySelector('#modal_1');
        const body = document.querySelector('body');
        modal_1.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal2 = () => {
        const modal_2 = document.querySelector('#modal_2');
        const body = document.querySelector('body');
        modal_2.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal3 = () => {
        const modal_3 = document.querySelector('#modal_3');
        const body = document.querySelector('body');
        modal_3.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal4 = () => {
        const modal_4 = document.querySelector('#modal_4');
        const body = document.querySelector('body');
        modal_4.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal5 = () => {
        const modal_5 = document.querySelector('#modal_5');
        const body = document.querySelector('body');
        modal_5.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal6 = () => {
        const modal_6 = document.querySelector('#modal_6');
        const body = document.querySelector('body');
        modal_6.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal7 = () => {
        const modal_7 = document.querySelector('#modal_7');
        const body = document.querySelector('body');
        modal_7.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal8 = () => {
        const modal_8 = document.querySelector('#modal_8');
        const body = document.querySelector('body');
        modal_8.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal9 = () => {
        const modal_9 = document.querySelector('#modal_9');
        const body = document.querySelector('body');
        modal_9.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal10 = () => {
        const modal_10 = document.querySelector('#modal_10');
        const body = document.querySelector('body');
        modal_10.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal11 = () => {
        const modal_11 = document.querySelector('#modal_11');
        const body = document.querySelector('body');
        modal_11.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal12 = () => {
        const modal_12 = document.querySelector('#modal_12');
        const body = document.querySelector('body');
        modal_12.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal13 = () => {
        const modal_13 = document.querySelector('#modal_13');
        const body = document.querySelector('body');
        modal_13.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal14 = () => {
        const modal_14 = document.querySelector('#modal_14');
        const body = document.querySelector('body');
        modal_14.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal15 = () => {
        const modal_15 = document.querySelector('#modal_15');
        const body = document.querySelector('body');
        modal_15.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal16 = () => {
        const modal_16 = document.querySelector('#modal_16');
        const body = document.querySelector('body');
        modal_16.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal17 = () => {
        const modal_17 = document.querySelector('#modal_17');
        const body = document.querySelector('body');
        modal_17.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal18 = () => {
        const modal_18 = document.querySelector('#modal_18');
        const body = document.querySelector('body');
        modal_18.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal19 = () => {
        const modal_19 = document.querySelector('#modal_19');
        const body = document.querySelector('body');
        modal_19.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal20 = () => {
        const modal_20 = document.querySelector('#modal_20');
        const body = document.querySelector('body');
        modal_20.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal21 = () => {
        const modal_21 = document.querySelector('#modal_21');
        const body = document.querySelector('body');
        modal_21.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal22 = () => {
        const modal_22 = document.querySelector('#modal_22');
        const body = document.querySelector('body');
        modal_22.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal23 = () => {
        const modal_23 = document.querySelector('#modal_23');
        const body = document.querySelector('body');
        modal_23.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal24 = () => {
        const modal_24 = document.querySelector('#modal_24');
        const body = document.querySelector('body');
        modal_24.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal25 = () => {
        const modal_25 = document.querySelector('#modal_25');
        const body = document.querySelector('body');
        modal_25.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal26 = () => {
        const modal_26 = document.querySelector('#modal_26');
        const body = document.querySelector('body');
        modal_26.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal27 = () => {
        const modal_27 = document.querySelector('#modal_27');
        const body = document.querySelector('body');
        modal_27.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal28 = () => {
        const modal_28 = document.querySelector('#modal_28');
        const body = document.querySelector('body');
        modal_28.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal29 = () => {
        const modal_29 = document.querySelector('#modal_29');
        const body = document.querySelector('body');
        modal_29.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    show_modal30 = () => {
        const modal_30 = document.querySelector('#modal_30');
        const body = document.querySelector('body');
        modal_30.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    show_modal31 = () => {
        const modal_31 = document.querySelector('#modal_31');
        const body = document.querySelector('body');
        modal_31.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal32 = () => {
        const modal_32 = document.querySelector('#modal_32');
        const body = document.querySelector('body');
        modal_32.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }
    show_modal33 = () => {
        const modal_33 = document.querySelector('#modal_33');
        const body = document.querySelector('body');
        modal_33.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal34 = () => {
        const modal_34 = document.querySelector('#modal_34');
        const body = document.querySelector('body');
        modal_34.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal35 = () => {
        const modal_35 = document.querySelector('#modal_35');
        const body = document.querySelector('body');
        modal_35.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal36 = () => {
        const modal_36 = document.querySelector('#modal_36');
        const body = document.querySelector('body');
        modal_36.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal37 = () => {
        const modal_37 = document.querySelector('#modal_37');
        const body = document.querySelector('body');
        modal_37.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal38 = () => {
        const modal_38 = document.querySelector('#modal_38');
        const body = document.querySelector('body');
        modal_38.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal39 = () => {
        const modal_39 = document.querySelector('#modal_39');
        const body = document.querySelector('body');
        modal_39.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal40 = () => {
        const modal_40 = document.querySelector('#modal_40');
        const body = document.querySelector('body');
        modal_40.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal41 = () => {
        const modal_41 = document.querySelector('#modal_41');
        const body = document.querySelector('body');
        modal_41.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal42 = () => {
        const modal_42 = document.querySelector('#modal_42');
        const body = document.querySelector('body');
        modal_42.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal43 = () => {
        const modal_43 = document.querySelector('#modal_43');
        const body = document.querySelector('body');
        modal_43.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal44 = () => {
        const modal_44 = document.querySelector('#modal_44');
        const body = document.querySelector('body');
        modal_44.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal45 = () => {
        const modal_45 = document.querySelector('#modal_45');
        const body = document.querySelector('body');
        modal_45.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal46 = () => {
        const modal_46 = document.querySelector('#modal_46');
        const body = document.querySelector('body');
        modal_46.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal47 = () => {
        const modal_47 = document.querySelector('#modal_47');
        const body = document.querySelector('body');
        modal_47.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal48 = () => {
        const modal_48 = document.querySelector('#modal_48');
        const body = document.querySelector('body');
        modal_48.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal49 = () => {
        const modal_49 = document.querySelector('#modal_49');
        const body = document.querySelector('body');
        modal_49.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal50 = () => {
        const modal_50 = document.querySelector('#modal_50');
        const body = document.querySelector('body');
        modal_50.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal51 = () => {
        const modal_51 = document.querySelector('#modal_51');
        const body = document.querySelector('body');
        modal_51.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }

    show_modal52 = () => {
        const modal_52 = document.querySelector('#modal_52');
        const body = document.querySelector('body');
        modal_52.classList.toggle('show_modal');
        body.classList.toggle('no_scroll');
        const menu_contenedor = document.querySelector('.menu_contenedor');
        menu_contenedor.classList.toggle('z_index');
    }


    render() {
        return (
            <section className="speakers">
                <h2>Speakers</h2>


                <div>
                    <div className="contenedor_speakers">
                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_12'} onClick={this.show_modal12}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/yigal-erlich-879869/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Yigal Erlich <span className="keynote">KEYNOTE SPEAKER</span></b>
                                <p>FOUNDER |  YOZMA FUND</p>
                            </div>
                        </div>

                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_37'} onClick={this.show_modal37}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/saulsinger/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Saul Singer <span className="keynote">KEYNOTE SPEAKER</span></b>
                                <p>Co-author  “Start-Up Nation: The Story of Israel's Economic Miracle”</p>
                            </div>
                        </div>

                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_43'} onClick={this.show_modal43}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/andres-ackermann-cfa-7232116/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">

                                <b>Andrés Ackermann</b>
                                <p>Principal Investment Officer - Head of Investment Funds | IDB Invest</p>
                            </div>
                        </div>

                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_38'} onClick={this.show_modal38}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/alfonsoamat/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Alfonso Amat</b>
                                <p>Head of Startup Business Development - LatAm en Amazon Web Services (AWS)</p>
                            </div>
                        </div>



                    </div>



                    <div className="contenedor_speakers">
                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_25'} onClick={this.show_modal25}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/marianoamartino/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Mariano Amartino</b>
                                <p>Managing Director | Microsoft for startups LATAM</p>
                            </div>
                        </div>

                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_49'} onClick={this.show_modal49}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/pjamini/"></a>
                                </div>

                            </div>
                            <div className="texto_speaker">

                                <b>Paimun Amini</b>
                                <p>Director of Venture Investments | Leaps by Bayer</p>
                            </div>
                        </div>

                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_45'} onClick={this.show_modal45}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/javierartigasherrera/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">

                                <b>Javier Artigas Herrera</b>
                                <p>CEO & Founder at Connectus Medical Group</p>
                            </div>
                        </div>


                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_11'} onClick={this.show_modal11}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/isaantonaccio/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Isabella Antonaccio</b>
                                <p>IT Aftercare Specialist, en Uruguay XXI</p>
                            </div>
                        </div>


                    </div>

                    <div className="contenedor_speakers">

                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_29'} onClick={this.show_modal29}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/jorge-arg%C3%BCello/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Jorge Argüello</b>
                                <p>Argentine Ambassador to the United States</p>
                            </div>
                        </div>


                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_5'} onClick={this.show_modal5}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/martinbenitezaramendia/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Martin Benitez</b>
                                <p>IT Investment Specialist | Uruguay XXI</p>
                            </div>
                        </div>
                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_31'} onClick={this.show_modal31}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/sarah-bennani-464990b/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Sarah Bennani</b>
                                <p>Investment Officer | IFC Private Equity Funds</p>
                            </div>
                        </div>

                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_14'} onClick={this.show_modal14}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/luisbermejo/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Luis Bermejo</b>
                                <p>Managing Partner | Alaya Capital Partners</p>
                            </div>
                        </div>





                    </div>

                    <div className="contenedor_speakers">
                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_3'} onClick={this.show_modal3}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ines-bonicelli-4872119/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Inés Bonicelli </b>
                                <p>Deputy Executive Director | Uruguay XXI</p>
                            </div>
                        </div>
                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_7'} onClick={this.show_modal7}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/jeronimo-bosch-42a224/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Jerónimo Bosch</b>
                                <p>Managing Partner | Grupo Pegasus</p>
                            </div>
                        </div>

                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_16'} onClick={this.show_modal16}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/isabellechaquiriand/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Isabelle Chaquiriand</b>
                                <p>President | Xcala </p>
                            </div>
                        </div>
                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_8'} onClick={this.show_modal8}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/roberto-chute-4b3310/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Roberto Chute</b>
                                <p>Partner | The Rohatyn Group</p>
                            </div>
                        </div>



                    </div>

                    <div className="contenedor_speakers">
                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_36'} onClick={this.show_modal36}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/carmen-correa-78594724/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Carmen Correa</b>
                                <p>COO at Pro Mujer | Board Member at Pymecapita</p>
                            </div>
                        </div>


                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_30'} onClick={this.show_modal30}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ivonnecuello/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Ivonne Cuello</b>
                                <p>CEO | LAVCA</p>
                            </div>
                        </div>

                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_27'} onClick={this.show_modal27}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/colincurvey/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Colin Curvey</b>
                                <p>Fund Head, IFC Asset Management Company | International Private Equity</p>
                            </div>
                        </div>
                        {/* SPEAKER */}
                        <div className="speaker" >
                            <div className="imagen_speaker" id={'speaker_26'} onClick={this.show_modal26}>

                                <div className="texto_hover">
                                    <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/julio-dittborn-b4b7932b/"></a>

                                </div>

                            </div>
                            <div className="texto_speaker">
                                <b>Julio Dittborn Chadwick</b>
                                <p>Principal Private Equity | Megeve Investments</p>
                            </div>
                        </div>





                    </div>
                </div>

                <div className="contenedor_speakers">
                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_24'} onClick={this.show_modal24}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ricardodonosoinsunza/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Ricardo Donoso</b>
                            <p>CoFounder & CEO Americas | Frontier Car Group / OLX Autos</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_20'} onClick={this.show_modal20}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/sergiofogel/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Sergio Fogel</b>
                            <p>Co-Founder en DLocal</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_52'} onClick={this.show_modal52}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/quingarcia/"></a>
                            </div>

                        </div>
                        <div className="texto_speaker">

                            <b>Quin García</b>
                            <p>Managing Director at Autotech Ventures, Mobility & Transportation Venture Capital Investor</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_10'} onClick={this.show_modal10}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/diego-gonzalez-bravo-b143821/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Diego Gonzalez Bravo</b>
                            <p>Executive VicePresident | ARCAP</p>
                        </div>
                    </div>
                </div>



                <div className="contenedor_speakers">

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_42'} onClick={this.show_modal42}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/francisco-guzm%C3%A1n-64277513/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Francisco Guzmán</b>
                            <p>Vice presidente ACVC</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_44'} onClick={this.show_modal44}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ignaciohecquet/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">

                            <b>Ignacio Hecquet</b>
                            <p>Head of Strategy and Transactions for Argentina, Uruguay and Paraguay  | EY </p>
                        </div>
                    </div>


                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_21'} onClick={this.show_modal21}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/hernan-kazah-95810/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Hernán Kazah</b>
                            <p>Co-Founder & Managing Partner | Kaszek Ventures</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_6'} onClick={this.show_modal6}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ramiro-lauzan-2a5181/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Ramiro Lauzan</b>
                            <p>Partner | L Catterton</p>
                        </div>
                    </div>




                </div>

                <div className="contenedor_speakers">
                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_19'} onClick={this.show_modal19}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/stephen-lile-b44aa1120/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Stephen Lile</b>
                            <p>Co-founder & Managing Partner | Oxbridge Capital Partners </p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_47'} onClick={this.show_modal47}>

                            <div className="texto_hover">
                                {/* <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/francisco-guzm%C3%A1n-64277513/"></a> */}

                            </div>

                        </div>
                        <div className="texto_speaker">

                            <b>Yoed Magen</b>
                            <p>Israeli ambassador to Uruguay </p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_39'} onClick={this.show_modal39}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/alejandro-mashad-329174/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Alejandro Mashad</b>
                            <p>Director at Centro de Entrepreneurship de la Universidad de San Andrés</p>
                        </div>
                    </div>


                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_32'} onClick={this.show_modal32}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/mangarelli/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Eduardo Mangarelli</b>
                            <p>President | Endeavor Uruguay Partner | IC Ventures</p>
                        </div>
                    </div>




                </div>
                <div className="contenedor_speakers">
                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_33'} onClick={this.show_modal33}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/ericmanlunas/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Eric Manlunas</b>
                            <p>Founder and Managing Partner at Wavemaker Partners</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_2'} onClick={this.show_modal2}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/andr%C3%A9s-meirovich-bellolio-20b2b29/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Andrés Meirovich</b>
                            <p>President | ACVC</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_28'} onClick={this.show_modal28}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/macarena-navarrete-91b2a06/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Macarena Navarrete</b>
                            <p>Country Managing Partner | EY Chile</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_4'} onClick={this.show_modal4}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/luisfelipeolivadiaz/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Luis Felipe Oliva</b>
                            <p>Head of Investments and Financing Division | Corfo</p>
                        </div>
                    </div>


                </div>

                <div className="contenedor_speakers">
                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_23'} onClick={this.show_modal23}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/eugeniop/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Eugenio Pace</b>
                            <p>CEO & CoFounder at Auth0</p>
                        </div>
                    </div>
                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_35'} onClick={this.show_modal35}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/patriciapastorg/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Patricia Pastor</b>
                            <p>Managing Director at GoHub by Global Omnium & General Partner at GoHub Ventures.</p>
                        </div>
                    </div>
                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_50'} onClick={this.show_modal50}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/matiaspeire/"></a>
                            </div>

                        </div>
                        <div className="texto_speaker">

                            <b>Matías Peire</b>
                            <p>Founder & CEO | GRIDX</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_13'} onClick={this.show_modal13}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/antoniarojase/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Antonia Rojas Eing</b>
                            <p>Partner | ALLVP</p>
                        </div>
                    </div>


                </div>

                <div className="contenedor_speakers">
                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_34'} onClick={this.show_modal34}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/maria-de-los-angeles-romo-a1b7a323//"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>María de los Ángeles Romo</b>
                            <p>Manager Startup Chile</p>
                        </div>
                    </div>
                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_18'} onClick={this.show_modal18}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/agrotondo/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Agustín Rotondo</b>
                            <p>Regional Manager | Wayra Hispam. </p>
                        </div>
                    </div>
                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_17'} onClick={this.show_modal17}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/msilvamartinez/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Manuel Silva</b>
                            <p>General Partner | Mouro Capital </p>
                        </div>
                    </div>
                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_1'} onClick={this.show_modal1}>

                            <div className="texto_hover">
                                {/* <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www..com/"></a> */}

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Alejandro Sorgentini</b>
                            <p>President | ARCAP</p>
                        </div>
                    </div>





                </div>

                <div className="contenedor_speakers">
                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_9'} onClick={this.show_modal9}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/jose-sosa-del-valle-b13b38/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>José Sosa del Valle</b>
                            <p>Partner | Lexington Partners</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_40'} onClick={this.show_modal40}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/meghansk/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Meghan Stevenson-Krausz</b>
                            <p>Director | INCA Ventures</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_15'} onClick={this.show_modal15}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/valentina-terranova-85496294/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Valentina Terranova</b>
                            <p>Parter | Draper Cygnus</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_22'} onClick={this.show_modal22}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/marialauratinelli/"></a>

                            </div>

                        </div>
                        <div className="texto_speaker">
                            <b>Maria Laura Tinelli</b>
                            <p>Director | Acrux Partners</p>
                        </div>
                    </div>





                </div>

                <div className="contenedor_speakers">

                    {/* SPEAKER */}
                    <div className="speaker" >
                        <div className="imagen_speaker" id={'speaker_51'} onClick={this.show_modal51}>

                            <div className="texto_hover">
                                <a target="_blank" rel="noopener noreferrer" className="linkedIn" href="https://www.linkedin.com/in/rodrigo-villar-9b9969/"></a>
                            </div>

                        </div>
                        <div className="texto_speaker">

                            <b>Rodrigo Villar</b>
                            <p>Founder Partner at New Ventures & Adobe Capital</p>
                        </div>
                    </div>

                    {/* SPEAKER */}
                    <div className="speaker none" >

                    </div>

                    {/* SPEAKER */}
                    <div className="speaker none" >

                    </div>

                    {/* SPEAKER */}
                    <div className="speaker none" >

                    </div>
                </div>


                <Modals />

            </section>
        )
    }
}

export default Speakers;