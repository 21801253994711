import React from 'react';
import cierre from "../../../assets/img/agenda/boton_cierre.png";
import flecha_agenda_jueves from "../../../assets/img/agenda/flecha-agenda-jueves.png"
import { Link } from 'react-scroll'


class Banner extends React.Component {
    state = {
        status: null
    }

    show_registro_ingles = () => {

        this.setState({
            status: 'ingles'
        })

        const modal_registro_ingles = document.querySelector('.modal_registro_ingles');
        // const body = document.querySelector('body');
        // body.classList.toggle('no_scroll');
        modal_registro_ingles.classList.toggle('show_modal_registro');


        const scroll = document.querySelector('.scroll');
        scroll.classList.toggle('fadeOut');

    }

    render() {

        return (

            // Componente del BANNER
            <section id={"seccion-banner"} className="banner">

                <div className="contenedor_banner">
                    <div className="fics_3d"></div>

                    {/* <div className="boton">
                        <div className="registro_boton">
                        <a  href="https://arcap.rebus.com.co/app/#/" >Login</a> <span>→</span>
                        </div>
                    </div> */}
                    <div className="modal_registro_ingles">
                        <div className="cerrar_div">< img onClick={this.show_registro_ingles} src={cierre} alt="cierre" /></div>

                        <div className="typeform-widget" data-url="https://form.typeform.com/to/rdR3rlW6" style={{ width: 100 + '%', height: 100 + '%' }}></div>



                    </div>
                </div>

                <div className="scroll">          <Link to="acerca" smooth={true} offset={-110}><img className="flecha_scroll" src={flecha_agenda_jueves} alt="flecha" /></Link>
                    <p className="texto_scroll">Scroll</p></div>

            </section>
        )
    }
}

export default Banner;